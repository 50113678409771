import React, { Component } from "react";
import { Modal } from "react-responsive-modal";
import HttpService from "../../services/HttpService";
import "./TapTheBall.css";

export default class TapTheBall extends Component {
    constructor(props) {
        super(props);
        this.playAnyway = this.playAnyway.bind(this);
        this.closeResultsModal = this.closeResultsModal.bind(this);
        this.fetchUserScore = this.fetchUserScore.bind(this);
        this.fetchTopTenScores = this.fetchTopTenScores.bind(this);
        this.play = this.play.bind(this);
        this.clickBall = this.clickBall.bind(this);
        this.ballNotClicked = this.ballNotClicked.bind(this);
        this.renderTopTenScores = this.renderTopTenScores.bind(this);
        this.state = {
            loading: false,
            displayPlayModal: false,
            displayResultsModal: false,
            inPlay: false,
            userHiScore: 0,
            topTenScores: [],
            newHiScore: false,
            currentScore: 0,
            firstAnimation: true,
            ballPosition: "",
            marginTop: 0,
            marginLeft: 0,
        };
    }

    componentDidMount() {
        if (this.props.isSignedIn) {
            this.fetchUserScore();
        } else {
            this.setState({ displayPlayModal: true });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.isSignedIn !== prevProps.isSignedIn) {
            if (this.props.isSignedIn) {
                this.setState({ displayPlayModal: false });
                this.fetchUserScore();
            } else {
                if (!this.state.displayPlayModal) {
                    this.setState({ displayPlayModal: true });
                }
            }
        }
    }

    async fetchUserScore() {
        let response = await HttpService.getUserScore();
        this.setState({ userHiScore: response.data });
    }

    async fetchTopTenScores() {
        let response = await HttpService.getTopTenUserScores("topten");
        this.setState({ topTenScores: response.data });
    }

    async fetchTopTenScoresNoAuth() {
        let response = await HttpService.getTopTenUserScoresNoAuth(
            "toptennoauth"
        );
        this.setState({ topTenScores: response.data });
    }

    playAnyway() {
        this.setState({ displayPlayModal: false });
    }

    closeResultsModal() {
        this.setState({ currentScore: 0 });
        this.setState({ displayResultsModal: false });
    }

    play() {
        this.setState({ inPlay: true });
        this.setState({ currentScore: -1 }, () => {
            this.clickBall(true);
        });
    }

    clickBall() {
        this.setState({ currentScore: this.state.currentScore + 1 });
        this.setState({ firstAnimation: !this.state.firstAnimation });
        this.setState({ ballPosition: "box-style" }, () => {
            let topRandom = Math.floor(Math.random() * 6);
            let leftRandom = Math.floor(Math.random() * 5);

            let top = topRandom * 64;
            let left = leftRandom * 64;

            if (this.state.firstAnimation) {
                this.setState({
                    ballPosition:
                        this.state.ballPosition + " animated bounceOutOne",
                });
            } else {
                this.setState({
                    ballPosition:
                        this.state.ballPosition + " animated bounceOutTwo",
                });
            }

            this.setState({ marginTop: top });
            this.setState({ marginLeft: left });
        });
    }

    ballNotClicked() {
        if (!this.state.inPlay) {
            return;
        }
        this.setState({ inPlay: false });
        this.setState({ loading: true });
        this.setState({ displayResultsModal: true }, async () => {
            if (this.props.isSignedIn) {
                if (this.state.currentScore > this.state.userHiScore) {
                    this.setState({ userHiScore: this.state.currentScore });
                    this.setState({ newHiScore: true });

                    await HttpService.updateUserScore({
                        score: this.state.currentScore,
                    });
                    await this.fetchTopTenScores();
                    this.setState({ loading: false });
                } else {
                    this.setState({ newHiScore: false });

                    if (this.state.topTenScores.length === 0) {
                        await this.fetchTopTenScores();
                        this.setState({ loading: false });
                    } else {
                        this.setState({ loading: false });
                    }
                }
            } else {
                if (this.state.currentScore > this.state.userHiScore) {
                    this.setState({ userHiScore: this.state.currentScore });
                }

                if (this.state.topTenScores.length === 0) {
                    await this.fetchTopTenScoresNoAuth();
                    this.setState({ loading: false });
                } else {
                    this.setState({ loading: false });
                }
            }
        });
    }

    renderTopTenScores() {
        if (!this.state.loading && this.state.topTenScores.length > 0) {
            return this.state.topTenScores.map((user, index) => {
                const {
                    position,
                    userId,
                    username,
                    nickname,
                    avatarUrl,
                    score,
                } = user;
                return (
                    <tr key={userId} className="tap-the-ball-row">
                        <td className="tap-the-ball-position tap-the-ball-row-left">
                            {position}
                        </td>
                        <td className="tap-the-ball-avatar-box tap-the-ball-row-center">
                            <img
                                className="tap-the-ball-avatar"
                                src={avatarUrl}
                                alt=""
                            />
                        </td>
                        <td className="tap-the-ball-username tap-the-ball-row-center">
                            {username}
                            <div
                                className={
                                    nickname
                                        ? "tap-the-ball-nickname"
                                        : "tap-the-ball-nickname-no-player"
                                }>
                                {nickname || "no linked player"}
                            </div>
                        </td>
                        <td className="tap-the-ball-score tap-the-ball-row-right">
                            {score}
                        </td>
                    </tr>
                );
            });
        }
    }

    render() {
        return (
            <div>
                <Modal
                    open={this.state.displayPlayModal}
                    onClose={() => this.playAnyway()}
                    center
                    classNames={{
                        modal: "tap-the-ball-play-modal",
                    }}>
                    <div className="sign-in-text">
                        Please sign in to submit score
                    </div>
                    <div className="center margin-bottom-20">
                        <button
                            className="play-anyway-button"
                            onClick={this.playAnyway}>
                            Play anyway
                        </button>
                    </div>
                </Modal>

                <Modal
                    open={this.state.displayResultsModal}
                    onClose={() => this.closeResultsModal()}
                    center
                    classNames={{
                        modal: "tap-the-ball-results-modal",
                    }}>
                    <div className="modal-hi-score-text">Hi-Scores</div>
                    {this.props.isSignedIn && this.state.newHiScore && (
                        <div className="center hi-score-detail">
                            New Hi-Score : {this.state.currentScore}
                        </div>
                    )}
                    {this.props.isSignedIn && !this.state.newHiScore && (
                        <div className="tap-the-ball-position">
                            <div className="hi-score-detail">
                                You didn't beat your previous Hi-Score
                            </div>
                            <div>
                                Score : {this.state.currentScore}
                            </div>
                        </div>
                    )}

                    {!this.props.isSignedIn && (
                        <div className="center hi-score-detail">
                            Your Hi-Score : {this.state.currentScore}
                        </div>
                    )}
                    <div className="underline"></div>

                    {this.state.loading && (
                        <div className="loading-gif-container">
                            <img
                                className="loading-gif"
                                src={require("../../assets/images/5v5ide_gif.gif")}
                                alt=""></img>
                        </div>
                    )}
                    <table>
                        <tbody>{this.renderTopTenScores()}</tbody>
                    </table>

                    <div className="center margin-bottom-20">
                        <button
                            className="ok-button"
                            onClick={this.closeResultsModal}>
                            Ok
                        </button>
                    </div>
                </Modal>

                <div className="center margin-top-20">
                    <div className="center hi-score-container">
                        <div className="your-hi-score">
                            Your Hi-Score: {this.state.userHiScore}
                        </div>
                        <div className="current-score">
                            Score : {this.state.currentScore}
                        </div>
                    </div>
                </div>

                <div>
                    <div className="game-border">
                        {!this.state.inPlay && (
                            <div className="margin-top-80">
                                <div className="center">
                                    <img
                                        className="shoot-logo"
                                        src={require("../../assets/images/shoot-logo.png")}
                                        alt=""
                                    />
                                </div>
                                <div className="center shoot-explain margin-top-100">
                                    Tap the ball as many times
                                </div>
                                <div className="center shoot-explain">
                                    as you can
                                </div>
                                <div className="center shoot-explain"></div>
                            </div>
                        )}
                        <div
                            className={this.state.ballPosition}
                            style={{
                                marginTop: this.state.marginTop,
                                marginLeft: this.state.marginLeft,
                            }}
                            onClick={this.clickBall}
                            onAnimationEnd={() => this.ballNotClicked()}></div>
                    </div>
                </div>
                {!this.state.inPlay && (
                    <div className="center margin-bottom-20">
                        <button
                            className="play-anyway-button"
                            onClick={this.play}>
                            Play
                        </button>
                    </div>
                )}
            </div>
        );
    }
}
