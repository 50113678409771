import React, { Component } from "react";
import MediaQuery from "react-responsive";
import HttpService from "../../services/HttpService";
import { Modal } from "react-responsive-modal";
import TimePeriod from "../../shared/TimePeriod";
import DateOption from "../../shared/DateOption";
import StatOption from "../../shared/StatOption";
import DatePicker from "react-date-picker";
import "./Players.css";

export default class Players extends Component {
    constructor(props) {
        super(props);
        this.fetchAllPlayerStats = this.fetchAllPlayerStats.bind(this);
        this.verifyLeague = this.verifyLeague.bind(this);
        this.activePlayerCheckbox = this.activePlayerCheckbox.bind(this);
        this.state = {
            loading: false,
            allPlayerStats: [],
            filteredPlayerStats: [],
            selectedStat: StatOption.TotalAppearances,
            openDateFilterModal: false,
            openStatModal: false,
            openQuestionModal: false,
            startDate: new Date(),
            endDate: new Date(),
            customDateExpanded: false,
            selectedDate: DateOption.AllTime,
            customDateError: "",
            inactivePlayerChecked: false
        };
    }

    async componentDidMount() {
        await this.verifyLeague();

        let playerStatCriteria = {
            LeagueId: this.props.league.id,
            StartDate: null,
            EndDate: null,
        };
        this.fetchAllPlayerStats(playerStatCriteria);

        this.setState({ startDate: new Date() });
        this.setState({ endDate: new Date() });
    }

    expandCustomDate() {
        this.setState({ customDateExpanded: !this.state.customDateExpanded });
    }

    async verifyLeague() {
        let url = this.props.location.pathname;
        let urlLeague = url.split("/")[1];

        if (urlLeague !== this.props.league.route) {
            await HttpService.getLeagues().then(async (res) => {
                const allLeagues = res.data;

                const desiredLeague = allLeagues.find(
                    (league) => league.route === urlLeague
                );

                if (desiredLeague !== undefined) {
                    this.props.setAppLeague(
                        desiredLeague.name,
                        desiredLeague.route,
                        desiredLeague.id
                    );
                }
            });
        }
    }

    fetchAllPlayerStats(playerStatCriteria) {
        this.setState({ loading: true, allPlayerStats: [] }, () => {
            HttpService.getAllPlayersStats(playerStatCriteria).then((res) => {
                const allPlayerStats = res.data;

                allPlayerStats.map((player) => {
                    player.goalRatio = this.calculateRatios(
                        player.oldGoalToGameRatio,
                        player.goalToGameRatio
                    );

                    player.assistRatio = this.calculateRatios(
                        player.oldAssistToGameRatio,
                        player.assistToGameRatio
                    );

                    player.formattedFastestBackToBackGoals =
                        this.EvaluateFastestBackToBackGoalString(
                            player.fastestBackToBackGoals
                        );

                    return player;
                });

                this.setState({ allPlayerStats });
                this.setState({ loading: false });

                this.filterPlayersByActiveStatus(allPlayerStats);
            });
        });
    }

    filterPlayersByActiveStatus(allPlayerStats) {
        if (this.state.inactivePlayerChecked) {
            this.setState({ filteredPlayerStats: allPlayerStats });
        } else {
            let filteredPlayerStats = allPlayerStats.filter(x => x.active === true);
            this.setState({ filteredPlayerStats });
        }
    }

    filterByDate(dateOption) {
        this.setState({ selectedDate: dateOption });

        let playerStatCriteria = {};
        let today = new Date();
        let currentYear = today.getFullYear();
        let currentMonth = today.getMonth();

        switch (dateOption) {
            case DateOption.AllTime:
                playerStatCriteria.LeagueId = this.props.league.id;
                playerStatCriteria.StartDate = null;
                playerStatCriteria.EndDate = null;
                this.fetchAllPlayerStats(playerStatCriteria);
                this.closeModal();

                break;
            case DateOption.ThisYear:
                playerStatCriteria.LeagueId = this.props.league.id;
                playerStatCriteria.StartDate = [currentYear, "01", "01"].join(
                    "-"
                );
                playerStatCriteria.EndDate = null;
                this.fetchAllPlayerStats(playerStatCriteria);
                this.closeModal();

                break;
            case DateOption.LastYear:
                playerStatCriteria.LeagueId = this.props.league.id;
                playerStatCriteria.StartDate = [
                    currentYear - 1,
                    "01",
                    "01",
                ].join("-");
                playerStatCriteria.EndDate = [currentYear - 1, "12", "31"].join(
                    "-"
                );
                this.fetchAllPlayerStats(playerStatCriteria);
                this.closeModal();

                break;
            case DateOption.ThisMonth:
                let endOfMonth = new Date(currentYear, currentMonth + 1, 0);
                let lastDayOfCurrentMonth = endOfMonth.getDate();

                playerStatCriteria.LeagueId = this.props.league.id;
                playerStatCriteria.StartDate = [
                    currentYear,
                    currentMonth + 1,
                    "01",
                ].join("-");
                playerStatCriteria.EndDate = [
                    currentYear,
                    currentMonth + 1,
                    lastDayOfCurrentMonth,
                ].join("-");
                this.fetchAllPlayerStats(playerStatCriteria);
                this.closeModal();

                break;
            case DateOption.LastMonth:
                let lastMonthYear = currentYear;
                let lastMonth = currentMonth - 1;

                if (currentMonth === 0) {
                    lastMonthYear = lastMonthYear - 1;
                    lastMonth = 11;
                }

                let endOfLastMonth = new Date(lastMonthYear, lastMonth + 1, 0);
                let lastDayOfLastMonth = endOfLastMonth.getDate();

                playerStatCriteria.LeagueId = this.props.league.id;
                playerStatCriteria.StartDate = [
                    lastMonthYear,
                    lastMonth + 1,
                    "01",
                ].join("-");
                playerStatCriteria.EndDate = [
                    lastMonthYear,
                    lastMonth + 1,
                    lastDayOfLastMonth,
                ].join("-");
                this.fetchAllPlayerStats(playerStatCriteria);
                this.closeModal();

                break;
            case DateOption.Custom:
                if (
                    this.state.startDate.getDate() ===
                    this.state.endDate.getDate() &&
                    this.state.startDate.getMonth() ===
                    this.state.endDate.getMonth() &&
                    this.state.startDate.getFullYear() ===
                    this.state.endDate.getFullYear()
                ) {
                    this.setState({
                        customDateError: "End Date must be after Start Date",
                    });
                    return;
                } else if (this.state.endDate < this.state.startDate) {
                    this.setState({
                        customDateError: "Start Date must be before End Date",
                    });
                    return;
                }

                if (
                    this.state.startDate.getFullYear().toString().length !==
                    4 ||
                    this.state.endDate.getFullYear().toString().length !== 4
                ) {
                    this.setState({
                        customDateError: "A year must be 4 digits",
                    });
                    return;
                }

                this.setState({ customDateError: "" });

                if (this.state.startDate < new Date("2019-03-13")) {
                    this.setState({ startDate: new Date("2019-03-13") }, () => {
                        playerStatCriteria = this.constructCustomDate();
                        this.fetchAllPlayerStats(playerStatCriteria);
                        this.closeModal();
                    });
                } else {
                    playerStatCriteria = this.constructCustomDate();
                    this.fetchAllPlayerStats(playerStatCriteria);
                    this.closeModal();
                }

                break;
            default:
        }
    }

    constructCustomDate() {
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;

        let playerStatCriteria = {};
        playerStatCriteria.LeagueId = this.props.league.id;
        playerStatCriteria.StartDate = [
            startDate.getFullYear(),
            startDate.getMonth() + 1,
            startDate.getDate(),
        ].join("-");
        playerStatCriteria.EndDate = [
            endDate.getFullYear(),
            endDate.getMonth() + 1,
            endDate.getDate(),
        ].join("-");

        return playerStatCriteria;
    }

    sortAllPlayerArray(statOption) {
        let filteredPlayerStats = this.state.filteredPlayerStats;
        let lessThanTenAppearancePlayers = filteredPlayerStats.filter((player) => {
            return player.totalAppearances < 10;
        });

        let moreThanTenAppearancePlayers = filteredPlayerStats.filter((player) => {
            return player.totalAppearances >= 10;
        });

        let lessThanSixAppearancePlayers = filteredPlayerStats.filter((player) => {
            return player.totalAppearances < 6;
        });

        let moreThanSixAppearancePlayers = filteredPlayerStats.filter((player) => {
            return player.totalAppearances >= 6;
        });

        switch (statOption) {
            case StatOption.TotalAppearances:
                filteredPlayerStats.sort((a, b) => {
                    return b.totalAppearances - a.totalAppearances;
                });
                break;
            case StatOption.TotalGoals:
                filteredPlayerStats.sort((a, b) => {
                    return b.totalGoals - a.totalGoals;
                });
                break;
            case StatOption.TotalUnassistedGoals:
                filteredPlayerStats.sort((a, b) => {
                    return b.totalUnassistedGoals - a.totalUnassistedGoals;
                });
                break;
            case StatOption.TotalAssists:
                filteredPlayerStats.sort((a, b) => {
                    return b.totalAssists - a.totalAssists;
                });
                break;
            case StatOption.MostGoalsScored:
                filteredPlayerStats.sort((a, b) => {
                    return b.mostGoalsScored - a.mostGoalsScored;
                });
                break;
            case StatOption.GoalPerGameRatio:
                moreThanTenAppearancePlayers.sort((a, b) => {
                    return b.goalToGameRatio - a.goalToGameRatio;
                });
                lessThanTenAppearancePlayers.sort((a, b) => {
                    return b.goalToGameRatio - a.goalToGameRatio;
                });

                filteredPlayerStats =
                    moreThanTenAppearancePlayers.concat(lessThanTenAppearancePlayers);
                break;
            case StatOption.AssistPerGameRatio:
                moreThanTenAppearancePlayers.sort((a, b) => {
                    return b.assistToGameRatio - a.assistToGameRatio;
                });
                lessThanTenAppearancePlayers.sort((a, b) => {
                    return b.assistToGameRatio - a.assistToGameRatio;
                });

                filteredPlayerStats =
                    moreThanTenAppearancePlayers.concat(lessThanTenAppearancePlayers);
                break;
            case StatOption.FastestBackToBackGoals:
                filteredPlayerStats.sort((a, b) => {
                    return a.fastestBackToBackGoals - b.fastestBackToBackGoals;
                });

                break;
            case StatOption.WinPercentage:
                moreThanTenAppearancePlayers.sort((a, b) => {
                    return b.winPercentage - a.winPercentage;
                });
                lessThanTenAppearancePlayers.sort((a, b) => {
                    return b.winPercentage - a.winPercentage;
                });

                filteredPlayerStats =
                    moreThanTenAppearancePlayers.concat(lessThanTenAppearancePlayers);
                break;
            case StatOption.LastSixMatchForm:

                moreThanSixAppearancePlayers.sort((a, b) => {
                    return b.lastSixMatchFormTally - a.lastSixMatchFormTally;
                });
                lessThanSixAppearancePlayers.sort((a, b) => {
                    return b.lastSixMatchFormTally - a.lastSixMatchFormTally;
                });

                filteredPlayerStats =
                    moreThanSixAppearancePlayers.concat(lessThanSixAppearancePlayers);
                break;
            default:
                filteredPlayerStats.sort((a, b) => {
                    return a.fullName - b.fullName;
                });
        }

        return filteredPlayerStats;
    }

    EvaluateFastestBackToBackGoalString(goalTimeSpan) {
        let timeSpan = parseInt(goalTimeSpan);
        let hours = Math.floor(timeSpan / TimePeriod.Hour);
        let minutes = Math.floor(
            (timeSpan - hours * TimePeriod.Hour) / TimePeriod.Minute
        );
        let seconds = Math.floor(
            timeSpan - hours * TimePeriod.Hour - minutes * TimePeriod.Minute
        );

        if (hours > 1) {
            return "---";
        }
        if (hours > 0) {
            return hours + "h " + minutes + "m " + seconds + "s";
        }
        if (minutes > 0) {
            return minutes + "m " + seconds + "s";
        }
        if (seconds > 0) {
            return seconds + " secs";
        }
        return "---";
    }

    calculateRatios(oldStat, newStat) {
        let parsedOldStat = parseFloat(oldStat);
        let parsedNewStat = parseFloat(newStat);

        if (parsedNewStat > parsedOldStat) {
            return "positive";
        } else if (parsedNewStat < parsedOldStat) {
            return "negative";
        } else {
            return "equal";
        }
    }

    viewPlayer(playerGuid) {
        this.props.history.push({
            pathname: "/" + this.props.league.route + "/player/" + playerGuid,
        });
    }

    openDateFilterModal() {
        this.setState({ openDateFilterModal: true });
    }

    openStatModal() {
        this.setState({ openStatModal: true });
    }

    openQuestionModal() {
        this.setState({ openQuestionModal: true });
    }

    closeModal() {
        this.setState({ openDateFilterModal: false });
        this.setState({ openStatModal: false });
        this.setState({ openQuestionModal: false });
        this.setState({ customDateExpanded: false });
    }

    changeStat(statOption) {
        this.closeModal();
        this.setState({ selectedStat: statOption });
    }

    activePlayerCheckbox() {
        this.setState({ inactivePlayerChecked: !this.state.inactivePlayerChecked }, () => {
            this.filterPlayersByActiveStatus(this.state.allPlayerStats);
        });
    }

    questionMarkModal() {

    }

    renderTableTopRow() {
        let customDate = "";
        if (this.state.selectedDate === DateOption.Custom) {
            let startDate = [
                this.state.startDate.getFullYear(),
                this.state.startDate.getMonth() + 1,
                this.state.startDate.getDate(),
            ].join("-");
            let endDate = [
                this.state.endDate.getFullYear(),
                this.state.endDate.getMonth() + 1,
                this.state.endDate.getDate(),
            ].join("-");

            customDate = startDate + " - " + endDate;
        }

        return (
            <tr className="disable-text-select">
                <td
                    className={
                        this.state.selectedDate === DateOption.Custom
                            ? "player-stats-top-row-custom"
                            : "player-stats-top-row"
                    }
                    onClick={() => this.openDateFilterModal()}>
                    {this.state.selectedDate === DateOption.Custom
                        ? customDate
                        : this.state.selectedDate}
                    <span className="float-right">▼</span>
                </td>
                <td
                    className="player-stats-top-row"
                    onClick={() => this.openStatModal()}>
                    {this.state.selectedStat}
                    <span className="float-right">▼</span>
                </td>
            </tr>
        );
    }

    renderFullTableTopRow() {
        let customDate = "";
        if (this.state.selectedDate === DateOption.Custom) {
            let startDate = [
                this.state.startDate.getFullYear(),
                this.state.startDate.getMonth() + 1,
                this.state.startDate.getDate(),
            ].join("-");
            let endDate = [
                this.state.endDate.getFullYear(),
                this.state.endDate.getMonth() + 1,
                this.state.endDate.getDate(),
            ].join("-");

            customDate = startDate + " - " + endDate;
        }

        return (
            <tr className="disable-text-select">
                <td
                    className="player-stats-top-row-date-full"
                    onClick={() => this.openDateFilterModal()}>
                    {this.state.selectedDate === DateOption.Custom
                        ? customDate
                        : this.state.selectedDate}
                    <span className="float-right">▼</span>
                </td>
                <td
                    className={
                        this.state.selectedStat === StatOption.TotalAppearances
                            ? "player-stats-top-row-full-selected"
                            : "player-stats-top-row-full"
                    }
                    onClick={() =>
                        this.setState({
                            selectedStat: StatOption.TotalAppearances,
                        })
                    }>
                    Total Appearances
                    {this.state.selectedStat ===
                        StatOption.TotalAppearances && (
                            <span className="float-right">▼</span>
                        )}
                </td>
                <td
                    className={
                        this.state.selectedStat === StatOption.TotalGoals
                            ? "player-stats-top-row-full-selected"
                            : "player-stats-top-row-full"
                    }
                    onClick={() =>
                        this.setState({
                            selectedStat: StatOption.TotalGoals,
                        })
                    }>
                    Total Goals
                    {this.state.selectedStat === StatOption.TotalGoals && (
                        <span className="float-right">▼</span>
                    )}
                </td>
                <td
                    className={
                        this.state.selectedStat === StatOption.TotalUnassistedGoals
                            ? "player-stats-top-row-full-selected"
                            : "player-stats-top-row-full"
                    }
                    onClick={() =>
                        this.setState({
                            selectedStat: StatOption.TotalUnassistedGoals,
                        })
                    }>
                    Total Unassisted Goals
                    {this.state.selectedStat === StatOption.TotalUnassistedGoals && (
                        <span className="float-right">▼</span>
                    )}
                </td>
                <td
                    className={
                        this.state.selectedStat === StatOption.TotalAssists
                            ? "player-stats-top-row-full-selected"
                            : "player-stats-top-row-full"
                    }
                    onClick={() =>
                        this.setState({
                            selectedStat: StatOption.TotalAssists,
                        })
                    }>
                    Total Assists
                    {this.state.selectedStat === StatOption.TotalAssists && (
                        <span className="float-right">▼</span>
                    )}
                </td>
                <td
                    className={
                        this.state.selectedStat === StatOption.MostGoalsScored
                            ? "player-stats-top-row-full-selected"
                            : "player-stats-top-row-full"
                    }
                    onClick={() =>
                        this.setState({
                            selectedStat: StatOption.MostGoalsScored,
                        })
                    }>
                    Most Goals Scored
                    {this.state.selectedStat === StatOption.MostGoalsScored && (
                        <span className="float-right">▼</span>
                    )}
                </td>
                <td
                    className={
                        this.state.selectedStat === StatOption.GoalPerGameRatio
                            ? "player-stats-top-row-full-selected"
                            : "player-stats-top-row-full"
                    }
                    onClick={() =>
                        this.setState({
                            selectedStat: StatOption.GoalPerGameRatio,
                        })
                    }>
                    Goals per Game
                    {this.state.selectedStat ===
                        StatOption.GoalPerGameRatio && (
                            <span className="float-right">▼</span>
                        )}
                </td>
                <td
                    className={
                        this.state.selectedStat ===
                            StatOption.AssistPerGameRatio
                            ? "player-stats-top-row-full-selected"
                            : "player-stats-top-row-full"
                    }
                    onClick={() =>
                        this.setState({
                            selectedStat: StatOption.AssistPerGameRatio,
                        })
                    }>
                    Assists per Game
                    {this.state.selectedStat ===
                        StatOption.AssistPerGameRatio && (
                            <span className="float-right">▼</span>
                        )}
                </td>
                <td
                    className={
                        this.state.selectedStat ===
                            StatOption.FastestBackToBackGoals
                            ? "player-stats-top-row-full-selected"
                            : "player-stats-top-row-full"
                    }
                    onClick={() =>
                        this.setState({
                            selectedStat: StatOption.FastestBackToBackGoals,
                        })
                    }>
                    Fastest B2B Goals
                    {this.state.selectedStat ===
                        StatOption.FastestBackToBackGoals && (
                            <span className="float-right">▼</span>
                        )}
                </td>
                <td
                    className={
                        this.state.selectedStat === StatOption.LastSixMatchForm
                            ? "player-stats-top-row-full-selected"
                            : "player-stats-top-row-full"
                    }
                    onClick={() =>
                        this.setState({
                            selectedStat: StatOption.LastSixMatchForm,
                        })
                    }>
                    Form
                    {this.state.selectedStat === StatOption.LastSixMatchForm && (
                        <span className="float-right">▼</span>
                    )}
                </td>
                <td
                    className={
                        this.state.selectedStat === StatOption.WinPercentage
                            ? "player-stats-top-row-full-selected"
                            : "player-stats-top-row-full"
                    }
                    onClick={() =>
                        this.setState({
                            selectedStat: StatOption.WinPercentage,
                        })
                    }>
                    Win %
                    {this.state.selectedStat === StatOption.WinPercentage && (
                        <span className="float-right">▼</span>
                    )}
                </td>
            </tr>
        );
    }

    renderTable(statOption) {
        let filteredPlayerStats = this.sortAllPlayerArray(statOption);

        return filteredPlayerStats.map((player, index) => {
            const {
                playerGuid,
                nickName,
                fullName,
                avatarUrl,
                totalAppearances,
                totalGoals,
                totalUnassistedGoals,
                totalAssists,
                mostGoalsScored,
                goalToGameRatio,
                oldGoalToGameRatio,
                assistToGameRatio,
                oldAssistToGameRatio,
                winPercentage,
                lastSixMatchForm,
                active
            } = player;

            return (
                <tr
                    key={playerGuid}
                    onClick={() => this.viewPlayer(playerGuid)}>
                    <td className="player-stats-index">{index + 1}</td>
                    <td className="player-stats-avatar">
                        <img
                            className="player-stats-avatar-icon"
                            src={avatarUrl}
                            alt=""
                        />
                    </td>
                    <td className="player-stats-player">
                        <div>
                            {nickName}
                            {!active && (<div className="player-stats-inactive">
                                Inactive
                            </div>)}
                        </div>
                        <div>
                            <div className="player-stats-fullname">
                                {fullName}
                            </div>
                            {totalAppearances < 10 && (
                                <div className="player-stats-less-than-10-apps">
                                    {totalAppearances}{" "}
                                    {totalAppearances === 1 ? "app" : "apps"}
                                </div>
                            )}
                        </div>
                    </td>
                    {statOption === StatOption.TotalAppearances && (
                        <td className="player-stats-stat right-align">
                            {totalAppearances}
                        </td>
                    )}
                    {statOption === StatOption.TotalGoals && (
                        <td className="player-stats-stat right-align">{totalGoals}</td>
                    )}
                    {statOption === StatOption.TotalUnassistedGoals && (
                        <td className="player-stats-stat right-align">{totalUnassistedGoals}</td>
                    )}
                    {statOption === StatOption.TotalAssists && (
                        <td className="player-stats-stat right-align">{totalAssists}</td>
                    )}
                    {statOption === StatOption.MostGoalsScored && (
                        <td className="player-stats-stat right-align">{mostGoalsScored}</td>
                    )}
                    {statOption === StatOption.GoalPerGameRatio && (
                        <td className="player-stats-stat right-align">
                            <span className="player-stats-small-ratio">
                                {oldGoalToGameRatio}
                            </span>{" "}
                            <img
                                className="player-ratio-change"
                                src={require("../../assets/images/" +
                                    player.goalRatio +
                                    ".png")}
                                alt=""
                            />{" "}
                            {goalToGameRatio}
                        </td>
                    )}
                    {statOption === StatOption.AssistPerGameRatio && (
                        <td className="player-stats-stat right-align">
                            <span className="player-stats-small-ratio">
                                {oldAssistToGameRatio}
                            </span>{" "}
                            <img
                                className="player-ratio-change"
                                src={require("../../assets/images/" +
                                    player.assistRatio +
                                    ".png")}
                                alt=""
                            />{" "}
                            {assistToGameRatio}
                        </td>
                    )}
                    {statOption === StatOption.FastestBackToBackGoals && (
                        <td className="player-stats-stat right-align">
                            {player.formattedFastestBackToBackGoals}
                        </td>
                    )}
                    {statOption === StatOption.LastSixMatchForm && (
                        <td className="player-stats-stat">
                            <div className="float-right">
                                {this.populateForm(lastSixMatchForm)}
                            </div>
                        </td>
                    )}
                    {statOption === StatOption.WinPercentage && (
                        <td className="player-stats-stat right-align">{winPercentage}%</td>
                    )}
                </tr>
            );
        });
    }

    populateForm(lastSixMatchForm) {
        if (lastSixMatchForm.length === 0) {
            return <div>---</div>;
        }

        let playedFourMatches = lastSixMatchForm.length >= 4;

        return lastSixMatchForm.map((form, index) => {

            return (
                <div key={index} className={`float-left 
                    ${playedFourMatches && index === 0 ? "faded-form-max" :
                        playedFourMatches && index === 1 ? "faded-form-medium" :
                            playedFourMatches && index === 2 ? "faded-form-min"
                                : ""}`}>
                    {form === "W" ?
                        <img
                            className="last-six-match-form"
                            src={require("../../assets/images/win.png")}
                            alt=""
                        />
                        : form === "L" ? <img
                            className="last-six-match-form"
                            src={require("../../assets/images/lose.png")}
                            alt=""
                        /> : <img
                            className="last-six-match-form"
                            src={require("../../assets/images/draw.png")}
                            alt=""
                        />}
                </div>
            );
        });
    }

    renderFullTable(statOption) {
        let filteredPlayerStats = this.sortAllPlayerArray(statOption);

        return filteredPlayerStats.map((player, index) => {
            const {
                playerGuid,
                nickName,
                fullName,
                avatarUrl,
                totalAppearances,
                totalGoals,
                totalUnassistedGoals,
                totalAssists,
                mostGoalsScored,
                goalToGameRatio,
                oldGoalToGameRatio,
                assistToGameRatio,
                oldAssistToGameRatio,
                winPercentage,
                lastSixMatchForm,
                active
            } = player;

            return (
                <tr
                    key={playerGuid}
                    onClick={() => this.viewPlayer(playerGuid)}>
                    <td className="player-stats-index-full">{index + 1}</td>
                    <td className="player-stats-avatar-full">
                        <img
                            className="player-stats-avatar-icon"
                            src={avatarUrl}
                            alt=""
                        />
                    </td>
                    <td className="player-stats-player-full">
                        <div>
                            {nickName}
                            {!active && (<div className="player-stats-inactive">
                                Inactive
                            </div>)}
                        </div>
                        <div>
                            <div className="player-stats-fullname">
                                {fullName}
                            </div>
                            {totalAppearances < 10 && (
                                <div className="player-stats-less-than-10-apps">
                                    {totalAppearances}{" "}
                                    {totalAppearances === 1 ? "app" : "apps"}
                                </div>
                            )}
                        </div>
                    </td>
                    <td className="player-stats-stat-full">
                        {totalAppearances}
                    </td>
                    <td className="player-stats-stat-full">{totalGoals}</td>
                    <td className="player-stats-stat-full">{totalUnassistedGoals}</td>
                    <td className="player-stats-stat-full">{totalAssists}</td>
                    <td className="player-stats-stat-full">{mostGoalsScored}</td>
                    <td className="player-stats-stat-full">
                        <span className="player-stats-small-ratio">
                            {oldGoalToGameRatio}
                        </span>{" "}
                        <img
                            className="player-ratio-change"
                            src={require("../../assets/images/" +
                                player.goalRatio +
                                ".png")}
                            alt=""
                        />{" "}
                        {goalToGameRatio}
                    </td>
                    <td className="player-stats-stat-full">
                        <span className="player-stats-small-ratio">
                            {oldAssistToGameRatio}
                        </span>{" "}
                        <img
                            className="player-ratio-change"
                            src={require("../../assets/images/" +
                                player.assistRatio +
                                ".png")}
                            alt=""
                        />{" "}
                        {assistToGameRatio}
                    </td>
                    <td className="player-stats-stat-full">
                        {player.formattedFastestBackToBackGoals}
                    </td>
                    <td className="player-stats-stat-full">
                        <div className="float-right">
                            {this.populateForm(lastSixMatchForm)}
                        </div>
                    </td>
                    <td className="player-stats-stat-full-end">
                        {winPercentage}%
                    </td>
                </tr >
            );
        });
    }

    render() {
        return (
            <div>
                <div className="disable-text-select player-stats-league-name">
                    {this.props.league.name}
                </div>
                <div className="float-left active-player">
                    <div className="center">
                        <label className="active-player-switch">
                            <input type="checkbox" id="activePlayerSwitch" defaultChecked={this.state.inactivePlayerChecked} onChange={this.activePlayerCheckbox} />
                            <span className="active-player-slider"></span>
                        </label>
                        <div className="white-text center">Show inactive players
                            <div className="question-mark-container" onClick={() => this.openQuestionModal()}>
                                <img src={require("../../assets/images/question.png")} className="question-mark" alt="x"></img>
                            </div>
                        </div>
                    </div>
                </div>
                <MediaQuery maxWidth={699}>
                    <div className="disable-text-select player-stats-container margin-top-20">
                        <table className="player-stats-table">
                            <tbody>{this.renderTableTopRow()}</tbody>
                        </table>
                        {!this.state.loading &&
                            this.state.filteredPlayerStats.length > 0 && (
                                <table className="player-stats-table">
                                    <tbody>
                                        {this.renderTable(
                                            this.state.selectedStat
                                        )}
                                    </tbody>
                                </table>
                            )}
                        {!this.state.loading &&
                            this.state.filteredPlayerStats.length === 0 && (
                                <div className="player-stats-no-data">
                                    No Data
                                </div>
                            )}
                        {this.state.loading && (
                            <div className="loading-gif-container margin-top-20">
                                <img
                                    className="loading-gif"
                                    src={require("../../assets/images/5v5ide_gif.gif")}
                                    alt=""></img>
                            </div>
                        )}
                    </div>
                </MediaQuery>
                <MediaQuery minWidth={700} maxWidth={1199}>
                    <div className="disable-text-select player-stats-container margin-top-20">
                        <table className="player-stats-table">
                            <tbody>{this.renderFullTableTopRow()}</tbody>
                        </table>
                        {!this.state.loading &&
                            this.state.filteredPlayerStats.length > 0 && (
                                <table className="player-stats-table">
                                    <tbody>
                                        {this.renderFullTable(
                                            this.state.selectedStat
                                        )}
                                    </tbody>
                                </table>
                            )}
                        {!this.state.loading &&
                            this.state.filteredPlayerStats.length === 0 && (
                                <div className="player-stats-no-data">
                                    No Data
                                </div>
                            )}
                        {this.state.loading && (
                            <div className="loading-gif-container margin-top-20">
                                <img
                                    className="loading-gif"
                                    src={require("../../assets/images/5v5ide_gif.gif")}
                                    alt=""></img>
                            </div>
                        )}
                    </div>
                </MediaQuery>
                <MediaQuery minWidth={1200}>
                    <div className="disable-text-select player-stats-container margin-top-20">
                        <table className="player-stats-table">
                            <tbody>{this.renderFullTableTopRow()}</tbody>
                        </table>
                        {!this.state.loading &&
                            this.state.filteredPlayerStats.length > 0 && (
                                <table className="player-stats-table">
                                    <tbody>
                                        {this.renderFullTable(
                                            this.state.selectedStat
                                        )}
                                    </tbody>
                                </table>
                            )}
                        {!this.state.loading &&
                            this.state.filteredPlayerStats.length === 0 && (
                                <div className="player-stats-no-data">
                                    No Data
                                </div>
                            )}
                        {this.state.loading && (
                            <div className="loading-gif-container margin-top-20">
                                <img
                                    className="loading-gif"
                                    src={require("../../assets/images/5v5ide_gif.gif")}
                                    alt=""></img>
                            </div>
                        )}
                    </div>
                </MediaQuery>

                <Modal
                    open={this.state.openStatModal}
                    onClose={() => this.closeModal()}
                    center
                    classNames={{
                        modal: "modal-stat",
                    }}>
                    <div className="disable-text-select modal-stat-header margin-top-10">
                        Stats
                    </div>
                    <div
                        className="disable-text-select modal-player-stats-option margin-top-20"
                        onClick={() =>
                            this.changeStat(StatOption.TotalAppearances)
                        }>
                        Total Appearances
                    </div>
                    <div
                        className="disable-text-select modal-player-stats-option margin-top-10"
                        onClick={() => this.changeStat(StatOption.TotalGoals)}>
                        Total Goals
                    </div>
                    <div
                        className="disable-text-select modal-player-stats-option margin-top-10"
                        onClick={() => this.changeStat(StatOption.TotalUnassistedGoals)}>
                        Total Unassisted Goals
                    </div>
                    <div
                        className="disable-text-select modal-player-stats-option margin-top-10"
                        onClick={() =>
                            this.changeStat(StatOption.TotalAssists)
                        }>
                        Total Assists
                    </div>
                    <div
                        className="disable-text-select modal-player-stats-option margin-top-10"
                        onClick={() =>
                            this.changeStat(StatOption.MostGoalsScored)
                        }>
                        Most Goals Scored
                    </div>
                    <div
                        className="disable-text-select modal-player-stats-option margin-top-10"
                        onClick={() =>
                            this.changeStat(StatOption.GoalPerGameRatio)
                        }>
                        Goals per Game Ratio
                    </div>
                    <div
                        className="disable-text-select modal-player-stats-option margin-top-10"
                        onClick={() =>
                            this.changeStat(StatOption.AssistPerGameRatio)
                        }>
                        Assists per Game Ratio
                    </div>
                    <div
                        className="disable-text-select modal-player-stats-option margin-top-10"
                        onClick={() =>
                            this.changeStat(StatOption.FastestBackToBackGoals)
                        }>
                        Fastest Back To Back Goals
                    </div>
                    <div
                        className="disable-text-select modal-player-stats-option margin-top-10"
                        onClick={() =>
                            this.changeStat(StatOption.LastSixMatchForm)
                        }>
                        Form
                    </div>
                    <div
                        className="disable-text-select modal-player-stats-option margin-top-10"
                        onClick={() =>
                            this.changeStat(StatOption.WinPercentage)
                        }>
                        Win Percentages
                    </div>
                </Modal>

                <Modal
                    open={this.state.openDateFilterModal}
                    onClose={() => this.closeModal()}
                    center
                    classNames={{
                        modal: this.state.customDateExpanded
                            ? "modal-date modal-date-expanded-height"
                            : "modal-date modal-date-height",
                    }}>
                    <div className="disable-text-select modal-stat-header margin-top-10">
                        Filter by Date
                    </div>
                    <div
                        className="disable-text-select modal-player-stats-option margin-top-20"
                        onClick={() => this.filterByDate(DateOption.AllTime)}>
                        All Time
                    </div>
                    <div
                        className="disable-text-select modal-player-stats-option margin-top-10"
                        onClick={() => this.filterByDate(DateOption.ThisYear)}>
                        This Year
                    </div>
                    <div
                        className="disable-text-select modal-player-stats-option margin-top-10"
                        onClick={() => this.filterByDate(DateOption.LastYear)}>
                        Last Year
                    </div>
                    <div
                        className="disable-text-select modal-player-stats-option margin-top-10"
                        onClick={() => this.filterByDate(DateOption.ThisMonth)}>
                        This Month
                    </div>
                    <div
                        className="disable-text-select modal-player-stats-option margin-top-10"
                        onClick={() => this.filterByDate(DateOption.LastMonth)}>
                        Last Month
                    </div>
                    <div
                        className="disable-text-select modal-player-stats-custom margin-top-10"
                        onClick={() => this.expandCustomDate()}>
                        Custom
                        <span className="custom-date-chevron">
                            <span
                                className={
                                    this.state.customDateExpanded
                                        ? "chevron-expanded"
                                        : "chevron-collapsed"
                                }>
                                ▼
                            </span>
                        </span>
                    </div>
                    {!this.state.customDateExpanded && (
                        <div className="modal-player-stats-custom-no-option"></div>
                    )}
                    {this.state.customDateExpanded && (
                        <div className="modal-player-stats-custom-option">
                            <div className="display-inline-block">
                                <div className="display-inline-block">
                                    <label className="date-picker-label">
                                        Start Date
                                    </label>
                                    <div className="date-picker-box">
                                        <DatePicker
                                            format="dd-MM-yyyy"
                                            openCalendarOnFocus={false}
                                            onChange={(value) =>
                                                this.setState({
                                                    startDate: value,
                                                })
                                            }
                                            value={this.state.startDate}
                                            clearIcon={null}
                                            className="date-picker"
                                        />
                                    </div>
                                </div>
                                <div className="display-inline-block margin-left-10">
                                    <label className="date-picker-label">
                                        End Date
                                    </label>
                                    <div className="date-picker-box">
                                        <DatePicker
                                            format="dd-MM-yyyy"
                                            autoFocus={false}
                                            openCalendarOnFocus={false}
                                            onChange={(value) =>
                                                this.setState({
                                                    endDate: value,
                                                })
                                            }
                                            value={this.state.endDate}
                                            clearIcon={null}
                                            className="date-picker"
                                        />
                                    </div>
                                </div>
                            </div>
                            {this.state.customDateError.length > 0 && (
                                <div className="center custom-date-error-text">
                                    {this.state.customDateError}
                                </div>
                            )}
                            <div className="center">
                                <button
                                    className="date-picker-apply-button"
                                    onClick={() =>
                                        this.filterByDate(DateOption.Custom)
                                    }>
                                    Apply
                                </button>
                            </div>
                        </div>
                    )}
                </Modal>

                <Modal
                    open={this.state.openQuestionModal}
                    onClose={() => this.closeModal()}
                    center
                    classNames={{
                        modal: "modal-question",
                    }}>
                    <div className="disable-text-select modal-question-header margin-top-5">
                        Inactive Players
                    </div>
                    <div
                        className="disable-text-select modal-question-text margin-top-20">
                        A player is considered inactive if they haven't played a game within the last 3 months
                    </div>
                </Modal>
            </div>
        );
    }
}
