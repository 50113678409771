import React, { Component } from "react";
import HttpService from "../../services/HttpService";
import AuthenticationService from "../../services/AuthenticationService";
import Select from "react-select";
import "./UserOption.css";
import "react-responsive-modal/styles.css";

export default class LinkPlayerToAccount extends Component {
    constructor(props) {
        super(props);
        this.fetchPendingApprovalStatus =
            this.fetchPendingApprovalStatus.bind(this);
        this.fetchUnlinkedPlayers = this.fetchUnlinkedPlayers.bind(this);
        this.linkPlayerRequest = this.linkPlayerRequest.bind(this);
        this.state = {
            expanded: false,
            unlinkedPlayers: [],
            selectedPlayer: 0,
            pendingApproval: false,
            playerLinked: false,
            submittingLink: false,
        };
    }

    expand() {
        if (this.state.expanded) {
            this.setState({ expanded: false });
        } else {
            this.setState({ expanded: true });
            this.fetchPendingApprovalStatus();
            this.fetchUnlinkedPlayers();
        }
    }

    fetchPendingApprovalStatus() {
        HttpService.getLinkPlayerApprovalStatus().then((res) => {
            const linkStatus = res.data;
            if (linkStatus.linkExists) {
                if (linkStatus.unlinked) {
                    this.setState({ pendingApproval: true });
                } else if (linkStatus.linked) {
                    this.setState({ playerLinked: true });
                }
            }
        });
    }

    async fetchUnlinkedPlayers() {
        let signedIn = await AuthenticationService.isSignedIn();
        if (signedIn) {
            HttpService.getUnlinkedPlayers().then((res) => {
                const unlinkedPlayers = res.data;

                const options = unlinkedPlayers.map((player) => ({
                    value: player.id,
                    name: player.name,
                }));

                this.setState({ unlinkedPlayers: options });
            });
        }
    }

    linkPlayerRequest() {
        if (this.state.selectedPlayer === 0) {
            return;
        }

        this.setState({ submittingLink: true }, () => {
            const playerId = {
                PlayerId: this.state.selectedPlayer.value,
            };

            HttpService.userPlayerLink(playerId)
                .then((res) => {
                    if (res.status === 200) {
                        this.setState({ pendingApproval: true });
                    } else {
                        this.setState({ submittingLink: false });
                    }
                })
                .catch((err) => {
                    this.setState({ submittingLink: false });
                });
        });
    }

    refresh() {
        this.props.refresh();
    }

    render() {
        return (
            <div>
                <div className="disable-text-select option separator">
                    <span
                        className="margin-top-12"
                        onClick={() => this.expand()}>
                        <span
                            className={
                                this.state.expanded
                                    ? "chevron-expanded"
                                    : "chevron-collapsed"
                            }>
                            ▼
                        </span>
                        Link Player to Account
                    </span>
                    <br />
                    {this.state.pendingApproval && (
                        <div
                            className={
                                this.state.expanded ? "center" : "hidden"
                            }>
                            <button
                                className="link-player-pending"
                                disabled={true}>
                                <div className="bold-text">
                                    Pending approval
                                </div>
                                <div className="small-text">
                                    This may take upto 24 hours
                                </div>
                            </button>
                        </div>
                    )}
                    {this.state.playerLinked && (
                        <div
                            className={
                                this.state.expanded ? "center" : "hidden"
                            }>
                            <div>
                                <button
                                    className="center link-player-pending"
                                    disabled={true}>
                                    <div className="bold-text">
                                        Player successfully linked
                                    </div>
                                </button>

                                <button
                                    className="center link-player-button"
                                    onClick={this.refresh}>
                                    Refresh Page
                                </button>
                            </div>
                        </div>
                    )}
                    {!this.state.pendingApproval && !this.state.playerLinked && (
                        <div className={this.state.expanded ? "" : "hidden"}>
                            <div className="center">
                                <div className="small-text">
                                    <div className="center-align">
                                        Select your name from the drop down list
                                    </div>
                                    <div className="center-align">
                                        to ensure your account is linked
                                    </div>
                                    <div className="center-align">
                                        to the correct player profile
                                    </div>
                                </div>
                            </div>
                            <div className="select-box margin-top-20">
                                <Select
                                    placeholder="Select a player"
                                    options={this.state.unlinkedPlayers}
                                    onChange={(value) =>
                                        this.setState({
                                            selectedPlayer: value,
                                        })
                                    }
                                    className="select-option"
                                    value={this.state.selectedPlayer}
                                    getOptionLabel={(player) => (
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}>
                                            <span style={{ marginLeft: 5 }}>
                                                {player.name}
                                            </span>
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="center margin-20">
                                <button
                                    className={
                                        this.state.selectedPlayer === 0 ||
                                        this.state.submittingLink
                                            ? "disabled-link-player-button"
                                            : "link-player-button"
                                    }
                                    onClick={this.linkPlayerRequest}
                                    disabled={
                                        this.state.submittingLink ||
                                        this.state.selectedPlayer === 0
                                    }>
                                    {this.state.submittingLink
                                        ? "Please wait"
                                        : "Link Player"}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
