import React, { Component } from "react";
import Users from "../user/User";
import Toast from "../toast/Toast";
import "./Header.css";

export default class Header extends Component {
    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this);
        this.goHome = this.goHome.bind(this);
        this.authenticated = this.authenticated.bind(this);
        this.state = {
            toastMsg: "",
            showBackButton: false,
        };
    }

    componentDidMount() {
        this.unlisten = this.props.history.listen((location) => {
            if (location.pathname.indexOf("player") > -1) {
                this.setState({ showBackButton: true });
            } else {
                this.setState({ showBackButton: false });
            }
        });
    }
    componentWillUnmount() {
        this.unlisten();
    }

    authenticated(isSignedIn) {
        this.props.authenticated(isSignedIn);
    }

    goBack() {
        this.props.history.goBack();
    }

    goHome() {
        this.props.history.push({
            pathname: "/",
        });
    }

    render() {
        return (
            <div className="App-header">
                <Toast msg={this.state.toastMsg} />
                <table>
                    <tbody>
                        <tr>
                            {this.state.showBackButton ? (
                                <th
                                    className={
                                        "chevron-goback disable-text-select" +
                                        (this.state.showBackButton
                                            ? ""
                                            : "hide")
                                    }
                                    onClick={this.goBack}>
                                    ▼
                                </th>
                            ) : (
                                <th></th>
                            )}
                            <th>
                                <img
                                    className="logo"
                                    src={require("../../assets/images/5v5ideLogoWhite.png")}
                                    alt=""
                                    onClick={this.goHome}
                                />
                            </th>
                            <th>
                                <Users authenticated={this.authenticated} />
                            </th>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}
