import React, { Component } from "react";
import "./TeamPlayers.css";

export default class TeamPlayers extends Component {
    constructor(props) {
        super(props);
        this.viewPlayer = this.viewPlayer.bind(this);
    }

    viewPlayer(playerGuid) {
        if (!playerGuid) {
            return;
        }
        this.props.history.push({
            pathname: "/" + this.props.league.route + "/player/" + playerGuid,
        });
    }

    renderTableHeader() {
        return (
            <tr className="teamplayers-header">
                {/* <th key={0} className="hometeam-header">
                    {this.props.homeTeam}
                </th>
                <th key={1} className="awayteam-header">
                    {this.props.awayTeam}
                </th> */}
                <td className="left-players-header"></td>

                <td colSpan={2} key={1} className="players-header">
                    Teams
                </td>
                <td className="right-players-header"></td>
            </tr>
        );
    }

    renderTableData() {
        let homePlayers = this.props.players.homeTeam;
        let awayPlayers = this.props.players.awayTeam;

        const rowLength =
            homePlayers.length < awayPlayers.length
                ? awayPlayers.length
                : homePlayers.length;

        const items = [];
        for (var i = 0; i < rowLength; i++) {
            let row = {};
            row.homeGuid = homePlayers[i] ? homePlayers[i].guid : null;
            row.homeName = homePlayers[i] ? homePlayers[i].name : null;
            row.homeAvatarUrl = homePlayers[i]
                ? homePlayers[i].avatarUrl
                : null;
            row.awayGuid = awayPlayers[i] ? awayPlayers[i].guid : null;
            row.awayName = awayPlayers[i] ? awayPlayers[i].name : null;
            row.awayAvatarUrl = awayPlayers[i]
                ? awayPlayers[i].avatarUrl
                : null;
            items.push(row);
        }

        return items.map((row, index) => {
            return (
                <tr className="row teamplayers-body" key={index}>
                    <td
                        className={
                            row.homeAvatarUrl === null ? "hide-player" : ""
                        }
                        onClick={() => this.viewPlayer(row.homeGuid)}>
                        <img
                            className="home-avatar"
                            src={row.homeAvatarUrl}
                            alt=""
                        />
                    </td>
                    <td
                        className={
                            "home-row font-size " +
                            (row.homeAvatarUrl === null ? "hide-player" : "")
                        }
                        onClick={() => this.viewPlayer(row.homeGuid)}>
                        {row.homeName}
                    </td>
                    <td
                        className={
                            "away-row font-size " +
                            (row.awayAvatarUrl === null ? "hide-player" : "")
                        }
                        onClick={() => this.viewPlayer(row.awayGuid)}>
                        {row.awayName}
                    </td>
                    <td
                        className={
                            row.awayAvatarUrl === null ? "hide-player" : ""
                        }
                        onClick={() => this.viewPlayer(row.awayGuid)}>
                        <img
                            className="away-avatar"
                            src={row.awayAvatarUrl}
                            alt=""
                        />
                    </td>
                </tr>
            );
        });
    }

    render() {
        if (this.props.homeTeam === undefined) {
            return null;
        }

        return (
            <div className="disable-text-select">
                <div className="team-players">
                    <table>
                        <tbody>{this.renderTableHeader()}</tbody>
                        <tbody>{this.renderTableData()}</tbody>
                    </table>
                </div>
            </div>
        );
    }
}
