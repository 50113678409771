import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore } from "redux";
import ReactGA from "react-ga";
import App from "./components/app/App";
import "./index.css";

const store = createStore(() => ({}));

const TRACKING_ID = "UA-131143992-2";
ReactGA.initialize(TRACKING_ID);

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("root")
);
