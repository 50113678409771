import React, { Component } from "react";
import "./UserOption.css";
import "react-responsive-modal/styles.css";
import AuthenticationService from "../../services/AuthenticationService";
import HttpService from "../../services/HttpService";

export default class SignIn extends Component {
    constructor(props) {
        super(props);
        this.expand = this.expand.bind(this);
        this.signIn = this.signIn.bind(this);
        this.signOut = this.signOut.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
        this.state = {
            signingIn: false,
            expanded: false,
            showForgotPassword: false,
            resettingPassword: false,
            passwordResetSent: false,
            resetEmail: "",
            email: "",
            password: "",
            emailError: "",
            passwordError: "",
            authenticationError: "",
        };
    }

    expand() {
        if (this.state.expanded) {
            this.setState({ expanded: false });
        } else {
            this.setState({ expanded: true });
            this.setState({ showForgotPasswword: false });
        }
    }

    signIn() {
        this.setState({ signingIn: true }, () => {
            this.resetValidation();

            const signIn = {
                Email: this.state.email,
                Password: this.state.password,
            };

            HttpService.authenticate(signIn)
                .then((res) => {
                    this.setState({ signingIn: false });
                    AuthenticationService.storeToken(res.data);
                    this.props.authenticated(true);
                    this.expand();
                })
                .catch((err) => {
                    this.setState({ signingIn: false });
                    this.validationResponse(err.response.data);
                    this.props.authenticated(false);
                });
        });
    }

    signOut() {
        this.setState({ signingIn: true }, async () => {
            let signedOut = await AuthenticationService.revokeToken();

            this.setState({ signingIn: false });
            if (signedOut) {
                this.props.authenticated(false);
                this.expand();
            }
        });
    }

    resetValidation() {
        this.setState({ emailError: "" });
        this.setState({ passwordError: "" });
        this.setState({ authenticationError: "" });
    }

    validationResponse(response) {
        if (response.errors) {
            if (response.errors.Email?.length > 0) {
                this.setState({ emailError: response.errors.Email[0] });
            }
            if (response.errors.Password?.length > 0) {
                this.setState({ passwordError: response.errors.Password[0] });
            }
        }
        if (response.length > 0 && response[0].property === "Authentication") {
            this.setState({ authenticationError: response[0].message });
        }
    }

    showForgotPasswword() {
        this.setState({ showForgotPassword: true });
    }

    resetPassword() {
        this.setState({ resettingPassword: true }, () => {
            const resetEmail = {
                Email: this.state.resetEmail,
            };

            HttpService.forgotPassword(resetEmail).then((res) => {
                this.setState({ passwordResetSent: true });
            });
        });
    }

    render() {
        return (
            <div className="disable-text-select option separator">
                <span className="margin-top-12" onClick={() => this.expand()}>
                    <span
                        className={
                            this.state.expanded
                                ? "chevron-expanded"
                                : "chevron-collapsed"
                        }>
                        ▼
                    </span>
                    {this.props.isSignedIn ? "Sign out" : "Sign in"}
                </span>
                <br />
                <div className={this.state.expanded ? "" : "hidden"}>
                    {this.props.isSignedIn ? (
                        <div className="center">
                            <button
                                className="sign-out-button"
                                onClick={this.signOut}>
                                Sign out
                            </button>
                        </div>
                    ) : (
                        <div className="center">
                            <div className="user-box">
                                <label className="label">Email</label>
                                <input
                                    type="text"
                                    className="textbox"
                                    value={this.state.email}
                                    onChange={(e) =>
                                        this.setState({
                                            email: e.target.value,
                                        })
                                    }></input>
                                {this.state.emailError.length > 0 && (
                                    <div className="error">
                                        {this.state.emailError}
                                    </div>
                                )}

                                <label className="label">Password</label>
                                <input
                                    type="password"
                                    className="textbox"
                                    value={this.state.password}
                                    onChange={(e) =>
                                        this.setState({
                                            password: e.target.value,
                                        })
                                    }></input>
                                {this.state.passwordError.length > 0 && (
                                    <div className="error">
                                        {this.state.passwordError}
                                    </div>
                                )}

                                <div>
                                    <div className="center">
                                        <button
                                            className={
                                                this.state.signingIn ||
                                                this.state.email.length === 0 ||
                                                this.state.password.length === 0
                                                    ? "disabled-sign-in-button"
                                                    : "sign-in-button"
                                            }
                                            onClick={this.signIn}
                                            disabled={
                                                this.state.signingIn ||
                                                this.state.email.length === 0 ||
                                                this.state.password.length === 0
                                            }>
                                            {this.state.signingIn
                                                ? "Please wait"
                                                : "Sign in"}
                                        </button>
                                    </div>
                                    {this.state.authenticationError.length >
                                        0 && (
                                        <div className="center center-error">
                                            {this.state.authenticationError}
                                        </div>
                                    )}
                                </div>

                                <div>
                                    <div
                                        className="center forgot-password-text"
                                        onClick={() =>
                                            this.setState({
                                                showForgotPasswword:
                                                    !this.state
                                                        .showForgotPasswword,
                                            })
                                        }>
                                        Forgot password?
                                    </div>
                                </div>

                                {this.state.showForgotPasswword &&
                                    !this.state.passwordResetSent && (
                                        <div className="center top-border-separator">
                                            <div>
                                                <div className="reset-email-text">
                                                    <div className="center">
                                                        Please enter your email
                                                        address
                                                    </div>
                                                    <div className="center">
                                                        to reset your password
                                                    </div>
                                                </div>
                                                <label className="label">
                                                    Email
                                                </label>
                                                <input
                                                    type="text"
                                                    className="textbox"
                                                    value={
                                                        this.state.resetEmail
                                                    }
                                                    onChange={(e) =>
                                                        this.setState({
                                                            resetEmail:
                                                                e.target.value,
                                                        })
                                                    }></input>
                                                <div className="center">
                                                    <button
                                                        className={
                                                            this.state
                                                                .resetEmail
                                                                .length === 0
                                                                ? "disabled-reset-password-button"
                                                                : "reset-password-button"
                                                        }
                                                        onClick={
                                                            this.resetPassword
                                                        }
                                                        disabled={
                                                            this.state
                                                                .resetEmail
                                                                .length === 0 ||
                                                            this.state
                                                                .resettingPassword
                                                        }>
                                                        Reset password
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                {this.state.showForgotPasswword &&
                                    this.state.passwordResetSent && (
                                        <div className="center">
                                            <button
                                                className="reset-password-pending"
                                                disabled={true}>
                                                <div>A password reset</div>
                                                <div>email has been sent</div>
                                                <div className="small-text">
                                                    It may be in your spam
                                                    folder
                                                </div>
                                            </button>
                                        </div>
                                    )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
