import React, { Component } from "react";
import { Modal } from "react-responsive-modal";

import AuthenticationService from "../../services/AuthenticationService";
import HttpService from "../../services/HttpService";
import SignIn from "../userOption/SignIn";
import CreateAccount from "../userOption/CreateAccount";
import Settings from "../userOption/Settings";
import MyPlayer from "../userOption/MyPlayer";
import EditPlayer from "../userOption/EditPlayer";
import LinkPlayerToAccount from "../userOption/LinkPlayerToAccount";

import "./User.css";

export default class User extends Component {
    constructor(props) {
        super(props);
        this.authenticated = this.authenticated.bind(this);
        this.fetchUserDetail = this.fetchUserDetail.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.playerCloseModal = this.playerCloseModal.bind(this);
        this.state = {
            openPopup: false,
            playerGuid: null,
            avatar: null,
            userName: "",
            isSignedIn: false,
        };
    }

    async componentDidMount() {
        let isSignedIn = await AuthenticationService.isSignedIn();
        this.authenticated(isSignedIn);
    }

    authenticated(isSignedIn) {
        this.props.authenticated(isSignedIn);
        this.setState({ isSignedIn: isSignedIn }, () => {
            if (isSignedIn) {
                this.fetchUserDetail();
            } else {
                this.setState({ userName: "" });
            }
        });
    }

    async refresh() {
        let refreshed = await AuthenticationService.refreshToken();
        if (refreshed) {
            this.fetchUserDetail();
        }
    }

    fetchUserDetail() {
        let activeToken = AuthenticationService.fetchToken();
        this.setState({ playerGuid: activeToken.playerGuid });
        this.setState({ avatar: activeToken.avatar });
        this.setState({
            userName: `${activeToken.firstname} ${activeToken.surname}`,
        });
    }

    async openModal() {
        this.setState({ openPopup: true });
        let isSignedIn = await AuthenticationService.isSignedIn();
        this.setState({ isSignedIn: isSignedIn }, () => {
            if (isSignedIn) {
                this.fetchUserDetail();
            } else {
                this.setState({ userName: "" });
            }
        });
    }

    closeModal() {
        this.setState({ openPopup: false });
    }

    playerCloseModal() {
        this.setState({ openPopup: false });
    }

    render() {
        return (
            <div>
                <img
                    className={
                        this.state.isSignedIn
                            ? "user-logo user-logo-signedIn"
                            : "user-logo user-logo-signedOut"
                    }
                    src={
                        this.state.isSignedIn && this.state.playerGuid
                            ? `${HttpService.baseUrl()}/player/avatar/${
                                  this.state.avatar
                              }`
                            : require("../../assets/images/userIcon.png")
                    }
                    alt=""
                    onClick={() => this.openModal()}
                />
                <div>
                    <Modal
                        open={this.state.openPopup}
                        onClose={() => this.closeModal()}
                        center
                        classNames={{
                            modal: "userModal",
                        }}>
                        <div className="disable-text-select option center-logo">
                            <img
                                className="logo"
                                src={require("../../assets/images/5v5ideLogoBlack.png")}
                                alt=""
                            />
                        </div>
                        <div className="center italic-name">
                            {this.state.userName}
                        </div>
                        <div className="user-body hide-native-scrollbar">
                            <SignIn
                                expanded={false}
                                authenticated={this.authenticated}
                                isSignedIn={this.state.isSignedIn}
                            />
                            {!this.state.isSignedIn && (
                                <div>
                                    <CreateAccount expanded={false} />
                                </div>
                            )}
                            {this.state.isSignedIn &&
                                this.state.playerGuid !== null && (
                                    <div>
                                        <MyPlayer
                                            closeModal={this.playerCloseModal}
                                        />
                                        <EditPlayer
                                            playerGuid={""}
                                            isSignedIn={this.state.isSignedIn}
                                            expanded={false}
                                        />
                                    </div>
                                )}

                            {this.state.isSignedIn &&
                                this.state.playerGuid === null && (
                                    <div>
                                        <LinkPlayerToAccount
                                            expanded={false}
                                            refresh={this.refresh}
                                        />
                                    </div>
                                )}

                            <Settings expanded={false} />
                        </div>
                    </Modal>
                </div>
            </div>
        );
    }
}
