const StatOption = {
    TotalAppearances: "Total Appearances",
    TotalGoals: "Total Goals",
    TotalUnassistedGoals: "Total Unassisted Goals",
    TotalAssists: "Total Assists",
    MostGoalsScored: "Most Goals Scored",
    GoalPerGameRatio: "Goals per Game",
    AssistPerGameRatio: "Assists per Game",
    FastestBackToBackGoals: "Fastest B2B Goals",
    LastSixMatchForm: "Form",
    WinPercentage: "Win Percentage",
};

export default StatOption;
