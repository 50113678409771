import React, { Component } from "react";
import HttpService from "../../services/HttpService";
import "./League.css";

export default class League extends Component {
    constructor(props) {
        super(props);
        this.fetchLeagues = this.fetchLeagues.bind(this);
        this.populateLeagues = this.populateLeagues.bind(this);
        this.state = {
            loading: false,
            leagues: [],
            selectedLeague: 0,
        };
    }

    async componentDidMount() {
        await this.fetchLeagues();
    }

    fetchLeagues() {
        this.setState({ loading: true }, () => {
            HttpService.getLeagues().then((res) => {
                const allLeagues = res.data;
                this.calculateLeagueFontSize(allLeagues);

                this.setState({
                    leagues: allLeagues,
                });
                this.setState({ loading: false });
            });
        });
    }

    setLeague(name, route, id) {
        if (this.state.selectedLeague === id) {
            this.setState({ selectedLeague: 0 });
        } else {
            this.setState({ selectedLeague: id });
        }

        this.props.setAppLeague(name, route, id);
    }

    navigateAway(destination) {
        this.props.history.push({
            pathname: "/" + this.props.league.route + "/" + destination,
        });
    }

    calculateLeagueFontSize(leagues) {
        return leagues.map((league, index) => {
            let length = league.name.length;
            if (length < 10) {
                return (league.size = 52);
            } else if (length < 15) {
                return (league.size = 36);
            } else {
                return (league.size = 30);
            }
        });
    }

    populateLeagues() {
        if (this.state.leagues.length > 0) {
            return this.state.leagues.map((league, index) => {
                const { name, route, id, icon, size } = league;
                return (
                    <div className="center margin-bottom-20" key={index}>
                        <div>
                            <div className="center">
                                <div
                                    className="league-grid league-container"
                                    onClick={() =>
                                        this.setLeague(name, route, id)
                                    }>
                                    <div>
                                        <img
                                            src={require("../../assets/images/leagues/" +
                                                icon +
                                                ".jpg")}
                                            alt="x"
                                            className="league-icon"
                                        />
                                    </div>
                                    <div className="league-name-container">
                                        <div
                                            className="league-name"
                                            style={{ fontSize: size }}>
                                            {name}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {this.state.selectedLeague === id && (
                                <div className="center margin-top-5">
                                    <div>
                                        <div
                                            className="players-matches-grid players-matches-container"
                                            onClick={() =>
                                                this.navigateAway("players")
                                            }>
                                            <div>
                                                <img
                                                    src={require("../../assets/images/players-icon.png")}
                                                    alt="x"
                                                    className="players-matches-icon"
                                                />
                                            </div>
                                            <div className="players-matches-text-container">
                                                <div className="players-matches-name">
                                                    Players
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="players-matches-grid players-matches-container margin-top-5"
                                            onClick={() =>
                                                this.navigateAway("match")
                                            }>
                                            <div>
                                                <img
                                                    src={require("../../assets/images/matches-icon.png")}
                                                    alt="x"
                                                    className="players-matches-icon"
                                                />
                                            </div>
                                            <div className="players-matches-text-container">
                                                <div className="players-matches-name">
                                                    Matches
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                );
            });
        }
    }

    render() {
        return (
            <div>
                {this.state.loading ? (
                    <div className="loading-gif-container">
                        <img
                            className="loading-gif"
                            src={require("../../assets/images/5v5ide_gif.gif")}
                            alt=""></img>
                    </div>
                ) : (
                    <div>
                        <div className="center margin-top-20">
                            <div>
                                <div className="disable-text-select margin-top-20">
                                    {this.populateLeagues()}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
