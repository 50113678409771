import React, { Component } from "react";
import Award from "../awards/Award";
import MediaQuery from "react-responsive";
import HttpService from "../../services/HttpService";
import TapTheBall from "../taptheball/TapTheBall";
import "./Player.css";

export default class Player extends Component {
    constructor(props) {
        super(props);
        this.state = {
            playerGuid: "",
            isOwnGoalPlayer: false,
            player: {},
            stats: {},
            awards: [],
            playerAwards: [],
            showAwards: false,
            testImage: null,
            goalToGameRatio: "empty",
            assistToGameRatio: "empty",
        };
    }

    componentDidMount() {
        this.unlisten = this.props.history.listen((location, action) => {
            this.playerSetup();
        });

        this.playerSetup();
    }

    componentWillUnmount(prevProps, prevState) {
        this.unlisten();
    }

    playerSetup() {
        let url = this.props.location.pathname;
        let playerGuidParam = url.split("/").pop();
        let guidPattern =
            /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
        let validGuid = guidPattern.test(playerGuidParam);

        if (validGuid) {
            this.setState({ playerGuid: playerGuidParam }, () => {
                this.setState({
                    isOwnGoalPlayer:
                        this.state.playerGuid ===
                        "a771c7cc-2827-4db7-91b9-3f2e40092fdc",
                });
                this.fetchPlayer();
                this.fetchPlayerStats();
                this.fetchAllAwards().then(() => {
                    this.fetchPlayerAwards();
                });
            });
        } else {
            this.unlisten();
            this.props.history.push("/" + this.props.league.route + "/match");
        }
    }

    fetchPlayer() {
        HttpService.getPlayer(this.state.playerGuid).then((res) => {
            const player = res.data;
            this.setState({ player });
        });
    }

    fetchPlayerStats() {
        HttpService.getPlayerStats(this.state.playerGuid).then((res) => {
            const stats = res.data;
            this.setState({ stats });
            this.calculateRatios();
        });
    }

    calculateRatios() {
        let goal = parseFloat(this.state.stats.goalToGameRatio);
        let oldGoal = parseFloat(this.state.stats.oldGoalToGameRatio);

        let assist = parseFloat(this.state.stats.assistToGameRatio);
        let oldAssist = parseFloat(this.state.stats.oldAssistToGameRatio);

        if (goal > oldGoal) {
            this.setState({ goalToGameRatio: "positive" });
        } else if (goal < oldGoal) {
            this.setState({ goalToGameRatio: "negative" });
        } else {
            this.setState({ goalToGameRatio: "equal" });
        }

        if (assist > oldAssist) {
            this.setState({ assistToGameRatio: "positive" });
        } else if (assist < oldAssist) {
            this.setState({ assistToGameRatio: "negative" });
        } else {
            this.setState({ assistToGameRatio: "equal" });
        }
    }

    fetchAllAwards() {
        return HttpService.getAwards().then((res) => {
            const awards = res.data;
            this.setState({ awards });
        });
    }

    fetchPlayerAwards() {
        HttpService.getPlayerAwards(this.state.playerGuid).then((res) => {
            const playerAwards = res.data;
            this.setState({ playerAwards });
        });
    }

    populateForm() {
        if (this.state.stats.lastSixMatchForm.length === 0) {
            return <div>---</div>;
        }

        let playedFourMatches = this.state.stats.lastSixMatchForm.length >= 4;

        return this.state.stats.lastSixMatchForm.map((form, index) => {

            return (
                <div key={index} className={`float-left 
                    ${playedFourMatches && index === 0 ? "faded-form-max" :
                        playedFourMatches && index === 1 ? "faded-form-medium" :
                            playedFourMatches && index === 2 ? "faded-form-min"
                                : ""}`}>
                    {form === "W" ?
                        <img
                            className="form"
                            src={require("../../assets/images/win.png")}
                            alt=""
                        />
                        : form === "L" ? <img
                            className="form"
                            src={require("../../assets/images/lose.png")}
                            alt=""
                        /> : <img
                            className="form"
                            src={require("../../assets/images/draw.png")}
                            alt=""
                        />}
                </div>
            );
        });
    }

    mostAssistsGivenTo() {
        if (this.state.stats.mostAssistsGivenTo.length === 0) {
            return (
                <div className="center most-assisted-container">
                    <div className="most-assisted-left">
                        <img
                            className="most-assisted-avatar"
                            src={require("../../assets/images/players-icon.png")}
                            alt=""
                        />
                    </div>
                    <div className="most-assisted-center">No assists</div>
                    <div className="most-assisted-right">0</div>
                </div>);
        }

        return this.state.stats.mostAssistsGivenTo.map((player, index) => {
            const { avatarUrl, name, count } = player;
            return (
                <div key={index} className="center most-assisted-container">
                    <div className="most-assisted-left">
                        <img
                            className="most-assisted-avatar"
                            src={avatarUrl}
                            alt=""
                        />
                    </div>
                    <div className="most-assisted-center">{name}</div>
                    <div className="most-assisted-right">{count}</div>
                </div>
            );
        });
    }

    mostAssistsReceivedFrom() {
        if (this.state.stats.mostAssistsReceivedFrom.length === 0) {
            return (
                <div className="center most-assisted-container">
                    <div className="most-assisted-left">
                        <img
                            className="most-assisted-avatar"
                            src={require("../../assets/images/players-icon.png")}
                            alt=""
                        />
                    </div>
                    <div className="most-assisted-center">No assists</div>
                    <div className="most-assisted-right">0</div>
                </div>);
        }

        return this.state.stats.mostAssistsReceivedFrom.map((player, index) => {
            const { avatarUrl, name, count } = player;
            return (
                <div key={index} className="center most-assisted-container">
                    <div className="most-assisted-left">
                        <img
                            className="most-assisted-avatar"
                            src={avatarUrl}
                            alt=""
                        />
                    </div>
                    <div className="most-assisted-center">{name}</div>
                    <div className="most-assisted-right">{count}</div>
                </div>
            );
        });
    }

    playerDetails() {
        return (
            <div className="player-header center">
                <div className="last-match">
                    <span className="golden">Last match: </span>{this.state.stats.lastMatch}
                </div>
                <div className="header-grid">
                    <div className="g player-avatar">
                        <img
                            className="g player-avatar-image"
                            src={this.state.player.avatarUrl}
                            alt=""
                        />
                    </div>
                    <div className="g nick-name">
                        <span className="nick-name-font">
                            {this.state.player.nickName}
                        </span>
                    </div>
                    <div className="g full-name">
                        <span className="full-name-font">
                            {this.state.player.name}
                        </span>
                        {Object.keys(this.state.stats).length !== 0 && !this.state.stats.active && (<span className="inactive-player">
                            Inactive
                        </span>)}
                    </div>
                    <div className="g nat-age-join">
                        <div className="nat-age-join-font">
                            <div className="flag-container f32">
                                <div
                                    className={
                                        "flag " + this.state.player.nationality
                                    }></div>
                            </div>
                            <span className="age-span">Age:</span>
                            {this.state.player.age === 0
                                ? "---"
                                : this.state.player.age}
                            <span className="joined-span">Joined:</span>
                            {this.state.player.joinedDate}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    playerStats() {
        return (
            <table className="stats-table">
                <tbody>
                    <tr>
                        <td>Total appearances</td>
                        <td>{this.state.stats.totalAppearances}</td>
                    </tr>
                    <tr>
                        <td>Total goals</td>
                        <td>{this.state.stats.totalGoals}</td>
                    </tr>
                    <tr>
                        <td>Total unassisted goals</td>
                        <td>{this.state.stats.totalUnassistedGoals}</td>
                    </tr>
                    <tr>
                        <td>Total assists</td>
                        <td>{this.state.stats.totalAssists}</td>
                    </tr>
                    <tr>
                        <td>Most goals scored in a game</td>
                        <td>{this.state.stats.mostGoalsScored}</td>
                    </tr>
                    <tr>
                        <td>Goals per game ratio</td>
                        <td>
                            <span className="ratio">
                                {this.state.stats.oldGoalToGameRatio}{" "}
                            </span>
                            <img
                                className="ratio-change"
                                src={require("../../assets/images/" +
                                    this.state.goalToGameRatio +
                                    ".png")}
                                alt=""
                            />
                            {this.state.stats.goalToGameRatio}
                        </td>
                    </tr>
                    <tr>
                        <td>Assists per game ratio</td>
                        <td>
                            <span className="ratio">
                                {this.state.stats.oldAssistToGameRatio}{" "}
                            </span>
                            <img
                                className="ratio-change"
                                src={require("../../assets/images/" +
                                    this.state.assistToGameRatio +
                                    ".png")}
                                alt=""
                            />
                            {this.state.stats.assistToGameRatio}
                        </td>
                    </tr>

                    <tr>
                        <td>Most assists given to</td>
                        <td>
                            <div className="center-right">
                                <div>
                                    {this.state.stats.mostAssistsGivenTo &&
                                        this.mostAssistsGivenTo()}
                                </div>
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td>Most assists received from</td>
                        <td>
                            <div className="center-right">
                                <div>
                                    {this.state.stats.mostAssistsReceivedFrom &&
                                        this.mostAssistsReceivedFrom()}
                                </div>
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td>Fastest back to back goals</td>
                        <td>{this.state.stats.fastestBackToBackGoals}</td>
                    </tr>
                    <tr>
                        <td>Form</td>
                        <td>
                            <div className="float-right-no-padding">
                                {this.state.stats.lastSixMatchForm && this.populateForm()}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Win percentage</td>
                        <td>{this.state.stats.winPercentage}</td>
                    </tr>
                    <tr>
                        <td>Total time played</td>
                        <td>{this.state.stats.totalTimePlayed}</td>
                    </tr>
                </tbody>
            </table>
        );
    }

    playerAwards() {
        return (
            <div className="awards-table">
                {this.state.awards.map((award, index) => {
                    const { id, name, description, icon, singleOccurence } =
                        award;

                    let playerAward = this.state.playerAwards.find((award) => {
                        return award.awardId === id;
                    });

                    if (playerAward === undefined) {
                        return null;
                    }

                    return (
                        <Award
                            key={id}
                            icon={icon}
                            title={name}
                            body={description}
                            single={singleOccurence}
                            show={playerAward.count > 0}
                            count={playerAward.count}
                        />
                    );
                })}
            </div>
        );
    }

    setShowAward(visible) {
        this.setState({ showAwards: visible });
    }

    render() {
        return (
            <div>
                {this.state.isOwnGoalPlayer && (
                    <div className="center">
                        <div>
                            {this.playerDetails()}
                            <TapTheBall isSignedIn={this.props.isSignedIn} />
                        </div>
                    </div>
                )}

                {!this.state.isOwnGoalPlayer && (
                    <div className="disable-text-select player-grid margin-top-10">
                        {this.playerDetails()}
                        <MediaQuery query="(max-width: 699px)">
                            <div>
                                <div className="margin-top-10 stats-trophies-grid ">
                                    <div
                                        className={
                                            "center stats-header " +
                                            (this.state.showAwards
                                                ? "header-unselected"
                                                : "header-selected")
                                        }
                                        onClick={() =>
                                            this.setShowAward(false)
                                        }>
                                        Stats
                                    </div>
                                    <div
                                        className={
                                            "center awards-header " +
                                            (this.state.showAwards
                                                ? "header-selected"
                                                : "header-unselected")
                                        }
                                        onClick={() => this.setShowAward(true)}>
                                        Awards
                                    </div>
                                </div>
                                <div className="center">
                                    {this.state.showAwards
                                        ? this.playerAwards()
                                        : this.playerStats()}
                                </div>
                            </div>
                        </MediaQuery>

                        <MediaQuery query="(min-width: 700px)">
                            <div className="player-stats">
                                {this.playerStats()}
                            </div>
                            <div className="player-awards hide-native-scrollbar">
                                {this.playerAwards()}
                            </div>
                        </MediaQuery>
                    </div>
                )}
            </div>
        );
    }
}
