import React, { Component } from "react";
import "./TopPlayer.css";

export default class TopPlayer extends Component {
    constructor(props) {
        super(props);
        this.state = { expanded: props.expand };
        this.viewPlayer = this.viewPlayer.bind(this);
    }

    expandShrinkTable() {
        if (this.state.expanded) {
            this.setState({ expanded: false });
        } else {
            this.setState({ expanded: true });
        }
    }

    viewPlayer(playerGuid) {
        this.props.history.push({
            pathname: "/" + this.props.league.route + "/player/" + playerGuid,
        });
    }

    renderTableHeader() {
        return (
            <tr onClick={() => this.expandShrinkTable()}>
                <th className="top-header top-left"></th>
                <th key={0} className="top-header top-central">
                    Top {this.props.topType}
                </th>
                <th className="top-header top-right">
                    <span
                        className={
                            this.state.expanded
                                ? "chevron-open"
                                : "chevron-close"
                        }>
                        ▼
                    </span>
                </th>
            </tr>
        );
    }

    renderFirstRow() {
        let topCount = 0;
        return this.props.data.map((player, index) => {
            if (index === 0) {
                topCount = player.count;
            } else if (player.count < topCount) {
                return null;
            }

            const { guid, homeAway, name, avatarUrl, count } = player;
            return (
                <tr
                    key={guid}
                    className="row"
                    onClick={() => this.viewPlayer(guid)}>
                    <td className="row-left">
                        <img className="top-avatar" src={avatarUrl} alt="" />
                    </td>
                    <td
                        className={
                            homeAway === "home"
                                ? "home-row-center top-font-size"
                                : "away-row-center top-font-size"
                        }>
                        {name}
                    </td>
                    <td
                        className={
                            homeAway === "home"
                                ? "home-row-right top-font-size"
                                : "away-row-right top-font-size"
                        }>
                        {count}
                    </td>
                </tr>
            );
        });
    }

    renderRemainderRows() {
        let topCount = 0;
        return this.props.data.map((player, index) => {
            if (index === 0) {
                topCount = player.count;
                return null;
            } else if (player.count === topCount) {
                return null;
            }

            const { guid, homeAway, name, avatarUrl, count } = player;
            return (
                <tr
                    key={guid}
                    className="row"
                    onClick={() => this.viewPlayer(guid)}>
                    <td className="row-left">
                        <img className="top-avatar" src={avatarUrl} alt="" />
                    </td>
                    <td
                        className={
                            homeAway === "home"
                                ? "home-row-center top-font-size"
                                : "away-row-center top-font-size"
                        }>
                        {name}
                    </td>
                    <td
                        className={
                            homeAway === "home"
                                ? "home-row-right top-font-size"
                                : "away-row-right top-font-size"
                        }>
                        {count}
                    </td>
                </tr>
            );
        });
    }

    render() {
        if (this.props.data === undefined) {
            return <div></div>;
        }

        if (this.props.data.length === 0) {
            return (
                <div className="disable-text-select">
                    <div className="top-player center">
                        <table className="top-border">
                            <tbody>
                                {this.renderTableHeader()}
                                <tr className="row">
                                    <td className="no-data-row-left"></td>
                                    <td className="no-data-row-center top-font-size">
                                        No Data
                                    </td>
                                    <td className="no-data-row-right top-font-size"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            );
        }

        return (
            <div className="disable-text-select">
                <div className="top-player center">
                    <table className="top-border">
                        <tbody>
                            {this.renderTableHeader()}
                            {this.renderFirstRow()}
                        </tbody>
                        <tbody className={this.state.expanded ? "" : "hidden"}>
                            {this.renderRemainderRows()}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
