import React, { Component } from "react";
import "./Award.css";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import MediaQuery from "react-responsive";

export default class Award extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openPopup: false,
            icon: "disabled-award",
        };
    }

    openModal() {
        this.setState({ openPopup: true });
    }

    closeModal() {
        this.setState({ openPopup: false });
    }

    emptyCloseIcon() {
        return <div></div>;
    }

    render() {
        return (
            <div className="awardbox">
                <MediaQuery query="(max-width:1019px)">
                    <div className="awardbox-position">
                        <img
                            src={
                                this.props.show
                                    ? require("../../assets/images/awards/" +
                                          this.props.icon +
                                          ".jpg")
                                    : require("../../assets/images/awards/disabled-award.jpg")
                            }
                            alt="x"
                            className="icon"
                            onClick={() => this.openModal()}
                        />
                        {!this.props.single && this.props.count > 0 && (
                            <div className="awardcount-position awardcount">
                                {this.props.count}
                            </div>
                        )}
                    </div>
                    <Modal
                        open={this.state.openPopup}
                        onClose={() => this.closeModal()}
                        center
                        closeIcon={this.emptyCloseIcon()}
                        classNames={{
                            modal: "customModal",
                        }}>
                        <div className="disable-text-select award-grid">
                            <img
                                src={
                                    this.props.show
                                        ? require("../../assets/images/awards/" +
                                              this.props.icon +
                                              ".jpg")
                                        : require("../../assets/images/awards/disabled-award.jpg")
                                }
                                alt="x"
                                className="g award-modal-icon"
                            />
                            <div
                                className={`g award-modal-title ${
                                    this.props.show ? "" : "disable-title"
                                }`}>
                                {this.props.title}
                            </div>
                            <div
                                className={`g award-modal-body ${
                                    this.props.show ? "" : "disable-body"
                                }`}>
                                {this.props.body}
                            </div>
                        </div>
                    </Modal>
                </MediaQuery>
                <MediaQuery query="(min-width: 1020px)">
                    <div
                        className={`customModal ${
                            this.props.show ? "" : "disable-award"
                        }`}>
                        <div className="disable-text-select award-grid">
                            <div className="awardbox-position">
                                <img
                                    src={
                                        this.props.show
                                            ? require("../../assets/images/awards/" +
                                                  this.props.icon +
                                                  ".jpg")
                                            : require("../../assets/images/awards/disabled-award.jpg")
                                    }
                                    alt="x"
                                    className="g award-modal-icon"
                                />
                                {!this.props.single && this.props.count > 0 && (
                                    <div className="awardcount-position awardcount">
                                        {this.props.count}
                                    </div>
                                )}
                            </div>
                            <div
                                className={`g award-modal-title ${
                                    this.props.show ? "" : "disable-title"
                                }`}>
                                {this.props.title}
                            </div>
                            <div
                                className={`g award-modal-body ${
                                    this.props.show ? "" : "disable-body"
                                }`}>
                                {this.props.body}
                            </div>
                        </div>
                    </div>
                </MediaQuery>
            </div>
        );
    }
}
