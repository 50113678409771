import React, { Component } from "react";
import "./DateSelector.css";

export default class DateSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dateArray: this.props.dateArray,
        };
        this.selectDate = this.selectDate.bind(this);
    }

    selectDate(event) {
        this.setState({ value: event.target.value });
        this.props.dateSelected(event.target.value);

        this.props.history.push({
            pathname:
                "/" + this.props.league.route + "/match/" + event.target.value,
        });
    }

    render() {
        const dateArray = this.props.dateOptions;
        return (
            <div className="center">
                <select
                    onChange={this.selectDate}
                    value={this.props.value}
                    className="matchdate-container">
                    {dateArray.map((item) => {
                        return (
                            <option
                                key={item.guid}
                                value={item.guid}
                                className="matchdate-option">
                                {item.dateTime}
                            </option>
                        );
                    })}
                </select>
            </div>
        );
    }
}
