import React, { Component } from "react";
import "./ScorerTable.css";

function AssisterDiv(props) {
    const showAssist = props.visible;
    const assister = props.name;
    const homeAway = props.homeAway;
    if (showAssist) {
        if (homeAway === "home") {
            return (
                <div className="assist assist-home-padding">
                    {assister}
                    <span className="rotate-chevron-home-assist">▼</span>
                </div>
            );
        } else {
            return (
                <div className="assist assist-away-padding">
                    <span className="rotate-chevron-away-assist">▼</span>
                    {assister}
                </div>
            );
        }
    }
    return null;
}

export default class ScorerTable extends Component {
    constructor(props) {
        super(props);
        this.state = { expanded: props.expand };
        this.viewPlayer = this.viewPlayer.bind(this);
    }

    viewPlayer(playerGuid) {
        this.props.history.push({
            pathname: "/" + this.props.league.route + "/player/" + playerGuid,
        });
    }

    ExpandShrinkTable() {
        if (this.state.expanded) {
            this.setState({ expanded: false });
        } else {
            this.setState({ expanded: true });
        }
    }

    renderTableHeader() {
        return (
            <tr
                className="scorer-header"
                onClick={() => this.ExpandShrinkTable()}>
                <th key={0} className="header hometeam-header" colSpan="2">
                    {this.props.result.homeTeam}
                </th>
                <th key={1} className="header score-header">
                    {this.props.result.homeScore}
                </th>
                <th key={2} className="header score-header">
                    <span
                        className={
                            this.state.expanded
                                ? "small-chevron-open"
                                : "small-chevron-close"
                        }>
                        ▼
                    </span>
                </th>
                <th key={3} className="header score-header">
                    {this.props.result.awayScore}
                </th>
                <th key={4} className="header awayteam-header" colSpan="2">
                    {this.props.result.awayTeam}
                </th>
            </tr>
        );
    }

    renderTableData() {
        let homeGoalCount = 0;
        let awayGoalCount = 0;

        return this.props.result.goals.map((scorer, index) => {
            const { id, homeAway, time, player, guid, assister, avatarUrl } =
                scorer;
            var showAssist = false;

            if (homeAway === "home") {
                homeGoalCount++;
                if (assister) {
                    showAssist = true;
                }
                return (
                    <tr key={id} onClick={() => this.viewPlayer(guid)}>
                        <td>
                            <img
                                className="avatar-left"
                                src={avatarUrl}
                                alt=""
                            />
                        </td>
                        <td className="center-cell home-colored-row align-right">
                            <div className="player player-left">
                                <div className="inline-block">
                                    <AssisterDiv
                                        visible={showAssist}
                                        name={assister}
                                        homeAway={homeAway}
                                    />
                                </div>
                                <div className="inline-block">{player}</div>
                            </div>
                        </td>
                        <td className="center-cell home-colored-row align-right align-bottom">
                            <div className="time time-home align-bottom">
                                {time}
                            </div>
                        </td>
                        <td className="incrementing-score center-cell home-colored-row bold">
                            {homeGoalCount}
                        </td>
                        <td className="incrementing-score right-rounded home-central-colored-row">
                            {awayGoalCount}
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                );
            } else if (homeAway === "away") {
                awayGoalCount++;
                if (assister) {
                    showAssist = true;
                }
                return (
                    <tr key={id} onClick={() => this.viewPlayer(guid)}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="incrementing-score left-rounded away-central-colored-row">
                            {homeGoalCount}
                        </td>
                        <td className="incrementing-score center-cell away-colored-row bold">
                            {awayGoalCount}
                        </td>
                        <td className="center-cell away-colored-row align-left align-bottom">
                            <div className="time time-away align-bottom">
                                {time}
                            </div>
                        </td>
                        <td className="center-cell away-colored-row align-left">
                            <div className="player player-right">
                                <div className="inline-block">{player}</div>
                                <div className="inline-block">
                                    <AssisterDiv
                                        visible={showAssist}
                                        name={assister}
                                        homeAway={homeAway}
                                    />
                                </div>
                            </div>
                        </td>
                        <td>
                            <img
                                className="avatar-right"
                                src={avatarUrl}
                                alt=""
                            />
                        </td>
                    </tr>
                );
            }
            return null;
        });
    }

    render() {
        if (Object.keys(this.props.result).length === 0) {
            return null;
        }

        return (
            <div className="disable-text-select">
                <div className="scorertable center">
                    <table id="scorers">
                        <tbody>{this.renderTableHeader()}</tbody>
                    </table>
                </div>
                <div className="scorertable scorerbody hide-native-scrollbar">
                    <table id="scorers">
                        <tbody className={this.state.expanded ? "" : "hidden"}>
                            {this.renderTableData()}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
