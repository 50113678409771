import React, { Component } from "react";
import AuthenticationService from "../../services/AuthenticationService";
import history from "../../services/History";
import "./UserOption.css";

export default class MyPlayer extends Component {
    constructor(props) {
        super(props);
        this.viewMyPlayer = this.viewMyPlayer.bind(this);
        this.state = {
            expanded: false,
        };
    }

    async viewMyPlayer() {
        let isSignedIn = await AuthenticationService.isSignedIn();
        if (isSignedIn) {
            this.props.closeModal();
            let activeToken = AuthenticationService.fetchToken();
            history.push("/player/" + activeToken.playerGuid, {
                playerGuid: activeToken.playerGuid,
            });
        }
    }

    render() {
        return (
            <div>
                <div className="disable-text-select option separator">
                    <span className="margin-top-12" onClick={this.viewMyPlayer}>
                        <span
                            className={
                                this.state.expanded
                                    ? "chevron-expanded"
                                    : "chevron-collapsed"
                            }>
                            ▼
                        </span>
                        My Player
                    </span>
                </div>
            </div>
        );
    }
}
