import React, { Component } from "react";
import { Router, Switch, Route } from "react-router-dom";
import RouteChangeTracker from "../route/RouteChangeTracker";
import history from "../../services/History";
import Header from "../header/Header";
import "./App.css";

import League from "../league/League";
import Match from "../match/Match";
import Player from "../player/Player";
import Players from "../players/Players";
import VerifyEmail from "../verifyemail/VerifyEmail";
import LinkPlayer from "../linkplayer/LinkPlayer";
import ResetPassword from "../resetpassword/ResetPassword";

export default class App extends Component {
    constructor(props) {
        super(props);
        this.setAppLeague = this.setAppLeague.bind(this);
        this.authenticated = this.authenticated.bind(this);
        this.state = {
            toastMsg: "",
            isSignedIn: false,
            league: { name: "All Leagues", route: "all", id: 1 },
        };
    }

    authenticated(isSignedIn) {
        this.setState({ isSignedIn: isSignedIn });
    }

    setAppLeague(name, route, id) {
        const leagueRoute = {
            name: name,
            route: route,
            id: id,
        };
        this.setState({ league: leagueRoute });
    }

    render() {
        return (
            <div className="cover">
                <Header
                    history={history}
                    league={this.state.league}
                    authenticated={this.authenticated}
                />
                <div className="App-body">
                    <Router history={history}>
                        <Switch>
                            <Route
                                path="/"
                                exact
                                render={(props) => (
                                    <League
                                        setAppLeague={this.setAppLeague}
                                        {...props}
                                        league={this.state.league}
                                    />
                                )}
                            />
                            <Route
                                path="*/match"
                                render={(props) => (
                                    <Match
                                        setAppLeague={this.setAppLeague}
                                        {...props}
                                        league={this.state.league}
                                    />
                                )}
                            />
                            <Route
                                path="*/player"
                                render={(props) => (
                                    <Player
                                        {...props}
                                        league={this.state.league}
                                        isSignedIn={this.state.isSignedIn}
                                    />
                                )}
                            />
                            <Route
                                path="*/players"
                                render={(props) => (
                                    <Players
                                        setAppLeague={this.setAppLeague}
                                        {...props}
                                        league={this.state.league}
                                    />
                                )}
                            />
                            <Route path="/verify" component={VerifyEmail} />
                            <Route path="/linkplayer" component={LinkPlayer} />
                            <Route
                                path="/resetpassword"
                                component={ResetPassword}
                            />
                        </Switch>
                        <RouteChangeTracker />
                    </Router>
                </div>
            </div>
        );
    }
}
