import React, { Component } from "react";
import { Modal } from "react-responsive-modal";
import HttpService from "../../services/HttpService";
import "./ResetPassword.css";

export default class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.resetPassword = this.resetPassword.bind(this);
        this.validationResponse = this.validationResponse.bind(this);
        this.redirectToHomePage = this.redirectToHomePage.bind(this);
        this.state = {
            openPopup: true,
            passwordReset: false,
            redirect: false,
            passwordMismatch: false,
            resettingPassword: false,
            password: "",
            confirmPassword: "",
            passwordError: "",
            confirmPasswordError: "",
            tokenError: "",
        };
    }

    redirectToHomePage() {
        this.closeModal();
        this.props.history.push({
            pathname: "/",
        });
    }

    resetPassword() {
        this.resetValidation();
        if (!this.fieldsPopulated()) {
            return;
        }
        this.setState({ passwordMismatch: false });
        this.setState({ resettingPassword: true }, () => {
            if (this.state.password !== this.state.confirmPassword) {
                this.setState({ passwordMismatch: true });
                this.setState({ resettingPassword: false });
                return;
            }

            let url = this.props.location.pathname;
            let resetToken = url.split("/").pop();

            let resetPasswordRequest = {
                Token: resetToken,
                Password: this.state.password,
            };

            if (resetToken.length > 0) {
                HttpService.resetPassword(resetPasswordRequest)
                    .then((res) => {
                        if (res.status === 200) {
                            this.setState({ passwordReset: true });

                            setTimeout(() => this.redirectToHomePage(), 3000);
                        }
                    })
                    .catch((err) => {
                        this.setState({ resettingPassword: false });
                        this.validationResponse(err.response.data);
                    });
            } else {
                this.setState({ resettingPassword: false });
                this.setState({ tokenError: true });
            }
        });
    }

    fieldsPopulated() {
        if (this.state.password.length === 0) {
            this.setState({ passwordError: "Enter a password" });
        }
        if (this.state.confirmPassword.length === 0) {
            this.setState({ confirmPasswordError: "Re-enter your password" });
        }

        if (
            this.state.password.length === 0 ||
            this.state.confirmPassword.length === 0
        ) {
            return false;
        }

        return true;
    }

    resetValidation() {
        this.setState({ passwordError: "" });
        this.setState({ confirmPasswordError: "" });
        this.setState({ tokenError: "" });
    }

    validationResponse(response) {
        if (response.errors) {
            if (response.errors.Password?.length > 0) {
                this.setState({ passwordError: response.errors.Password[0] });
            }
        }
        if (response.length > 0 && response[0].property === "Token") {
            this.setState({ tokenError: response[0].message });
        }
    }

    closeModal() {
        this.setState({ openPopup: false });
    }

    render() {
        return (
            <div>
                <Modal
                    open={this.state.openPopup}
                    onClose={() => this.closeModal()}
                    center
                    classNames={{
                        modal: "resetPasswordModal",
                    }}>
                    <div className="disable-text-select center-logo">
                        <img
                            className="logo"
                            src={require("../../assets/images/5v5ideLogoBlack.png")}
                            alt=""
                        />
                    </div>
                    <div className="center margin-top-20">
                        {this.state.tokenError.length === 0 &&
                            !this.state.passwordReset && (
                                <div>
                                    <div className="center margin-top-20">
                                        Please enter your new password
                                    </div>

                                    <label className="label margin-top-20">
                                        Password
                                    </label>
                                    <input
                                        type="password"
                                        className="textbox"
                                        value={this.state.password}
                                        onChange={(e) =>
                                            this.setState({
                                                password: e.target.value,
                                            })
                                        }></input>
                                    {this.state.passwordError.length > 0 && (
                                        <div className="error">
                                            {this.state.passwordError}
                                        </div>
                                    )}

                                    <label className="label margin-top-20">
                                        Confirm password
                                    </label>
                                    <input
                                        type="password"
                                        className="textbox"
                                        value={this.state.confirmPassword}
                                        onChange={(e) =>
                                            this.setState({
                                                confirmPassword: e.target.value,
                                            })
                                        }></input>

                                    {this.state.confirmPasswordError.length >
                                        0 && (
                                        <div className="error">
                                            {this.state.confirmPasswordError}
                                        </div>
                                    )}

                                    {this.state.passwordMismatch && (
                                        <div className="center password-match-error">
                                            Passwords do not match
                                        </div>
                                    )}

                                    <button
                                        className={
                                            this.state.resettingPassword
                                                ? "center disabled-reset-button"
                                                : "center reset-button"
                                        }
                                        onClick={this.resetPassword}
                                        disabled={this.state.resettingPassword}>
                                        {this.state.resettingPassword
                                            ? "Please wait"
                                            : "Reset password"}
                                    </button>
                                </div>
                            )}

                        {this.state.passwordReset && (
                            <div className="margin-top-50">
                                <div>
                                    <div className="center margin-top-20">
                                        Your password has been
                                    </div>
                                    <div className="center">
                                        successfully reset
                                    </div>
                                </div>
                                <div className="margin-top-50">
                                    <button
                                        className="center reset-button"
                                        onClick={this.redirectToHomePage}>
                                        <div>Go to home page</div>
                                    </button>
                                </div>
                            </div>
                        )}

                        {this.state.tokenError.length > 0 && (
                            <div>
                                <div className="center error-text margin-top-20">
                                    {this.state.tokenError}
                                </div>

                                <div className="center margin-top-20">
                                    There was a problem when requesting
                                </div>
                                <div className="center">
                                    your password reset
                                </div>
                                <div className="center margin-top-12">
                                    For further assistance, please contact
                                </div>
                                <div className="center ">
                                    <a href="mailto:admin@5v5ide.com">
                                        admin@5v5ide.com
                                    </a>
                                </div>

                                <button
                                    className="center reset-button"
                                    onClick={this.redirectToHomePage}>
                                    <div>Go to home page</div>
                                </button>
                            </div>
                        )}
                    </div>
                </Modal>
            </div>
        );
    }
}
