import React, { Component } from "react";
import {
    loadCaptchaEnginge,
    LoadCanvasTemplate,
    validateCaptcha,
} from "react-simple-captcha";
import HttpService from "../../services/HttpService";
import "react-responsive-modal/styles.css";
import "./UserOption.css";

export default class CreateAccount extends Component {
    constructor(props) {
        super(props);
        this.createAccount = this.createAccount.bind(this);
        this.fieldsPopulated = this.fieldsPopulated.bind(this);
        this.resetValidation = this.resetValidation.bind(this);
        this.verifyCaptcha = this.verifyCaptcha.bind(this);
        this.state = {
            expanded: false,
            creatingAccount: false,
            accountCreated: false,
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            confirmPassword: "",
            passwordMismatch: false,
            firstNameError: "",
            lastNameError: "",
            emailError: "",
            passwordError: "",
            confirmPasswordError: "",
            captchaValue: "",
            verified: false,
        };
    }

    componentDidMount() {
        loadCaptchaEnginge(6, "goldenrod", "black");
    }

    expand() {
        this.state.expanded
            ? this.setState({ expanded: false })
            : this.setState({ expanded: true });
    }

    fieldsPopulated() {
        if (this.state.firstName.length === 0) {
            this.setState({ firstNameError: "First name cannot be empty" });
        }
        if (this.state.lastName.length === 0) {
            this.setState({ lastNameError: "Last name cannot be empty" });
        }
        if (this.state.email.length === 0) {
            this.setState({ emailError: "Email cannot be empty" });
        }
        if (this.state.password.length === 0) {
            this.setState({ passwordError: "Enter a password" });
        }
        if (this.state.confirmPassword.length === 0) {
            this.setState({ confirmPasswordError: "Re-enter your password" });
        }

        if (
            this.state.firstName.length === 0 ||
            this.state.lastName.length === 0 ||
            this.state.email.length === 0 ||
            this.state.password.length === 0 ||
            this.state.confirmPassword.length === 0
        ) {
            return false;
        }

        return true;
    }

    createAccount() {
        this.resetValidation();
        this.setState({ passwordMismatch: false });
        this.setState({ creatingAccount: true }, () => {
            if (!this.fieldsPopulated()) {
                this.setState({ creatingAccount: false });
                return;
            }
            if (this.state.password !== this.state.confirmPassword) {
                this.setState({ passwordMismatch: true });
                this.setState({ creatingAccount: false });
                return;
            }

            const createAccount = {
                Firstname: this.state.firstName,
                Surname: this.state.lastName,
                Email: this.state.email,
                Password: this.state.password,
            };

            HttpService.createAccount(createAccount)
                .then((res) => {
                    this.setState({ creatingAccount: false });
                    this.setState({ accountCreated: true });
                })
                .catch((err) => {
                    this.setState({ creatingAccount: false });
                    this.validationResponse(err.response.data);
                });
        });
    }

    resetValidation() {
        this.setState({ firstNameError: "" });
        this.setState({ lastNameError: "" });
        this.setState({ emailError: "" });
        this.setState({ passwordError: "" });
        this.setState({ confirmPasswordError: "" });
    }

    validationResponse(response) {
        if (response.errors) {
            if (response.errors.Email?.length > 0) {
                this.setState({ emailError: response.errors.Email[0] });
            }
            if (response.errors.Password?.length > 0) {
                this.setState({ passwordError: response.errors.Password[0] });
            }
        }
        if (response.length > 0) {
            response.forEach((resp) => {
                if (resp.property === "Firstname") {
                    this.setState({ firstNameError: resp.message });
                } else if (resp.property === "Surname") {
                    this.setState({ lastNameError: resp.message });
                } else if (resp.property === "Email") {
                    this.setState({ emailError: resp.message });
                } else if (resp.property === "Password") {
                    this.setState({ passwordError: resp.message });
                }
            });
        }
    }

    verifyCaptcha() {
        if (validateCaptcha(this.state.captchaValue)) {
            this.setState({ verified: true });
        } else {
            this.setState({ verified: false });
            this.setState({ captchaValue: "" });
        }
    }

    render() {
        return (
            <div>
                <div className="disable-text-select option separator">
                    <span
                        className="margin-top-12"
                        onClick={() => this.expand()}>
                        <span
                            className={
                                this.state.expanded
                                    ? "chevron-expanded"
                                    : "chevron-collapsed"
                            }>
                            ▼
                        </span>
                        Create Account
                    </span>
                    <br />
                    <div className={this.state.expanded ? "" : "hidden"}>
                        <div className="center">
                            {!this.state.accountCreated && (
                                <div className="user-box">
                                    <form>
                                        <label className="label">
                                            First name
                                        </label>
                                        <input
                                            type="text"
                                            className="textbox"
                                            value={this.state.firstName}
                                            onChange={(e) =>
                                                this.setState({
                                                    firstName: e.target.value,
                                                })
                                            }></input>
                                        {this.state.firstNameError.length >
                                            0 && (
                                            <div className="error">
                                                {this.state.firstNameError}
                                            </div>
                                        )}

                                        <label className="label">
                                            Last name
                                        </label>
                                        <input
                                            type="text"
                                            className="textbox"
                                            value={this.state.lastName}
                                            onChange={(e) =>
                                                this.setState({
                                                    lastName: e.target.value,
                                                })
                                            }></input>
                                        {this.state.lastNameError.length >
                                            0 && (
                                            <div className="error">
                                                {this.state.lastNameError}
                                            </div>
                                        )}

                                        <label className="label">Email</label>
                                        <input
                                            type="text"
                                            className="textbox"
                                            value={this.state.email}
                                            onChange={(e) =>
                                                this.setState({
                                                    email: e.target.value,
                                                })
                                            }></input>
                                        {this.state.emailError.length > 0 && (
                                            <div className="error">
                                                {this.state.emailError}
                                            </div>
                                        )}

                                        <label className="label">
                                            Password
                                        </label>
                                        <input
                                            type="password"
                                            className="textbox"
                                            value={this.state.password}
                                            onChange={(e) =>
                                                this.setState({
                                                    password: e.target.value,
                                                })
                                            }></input>
                                        {this.state.passwordError.length >
                                            0 && (
                                            <div className="error">
                                                {this.state.passwordError}
                                            </div>
                                        )}

                                        <label className="label">
                                            Confirm password
                                        </label>
                                        <input
                                            type="password"
                                            className="textbox"
                                            value={this.state.confirmPassword}
                                            onChange={(e) =>
                                                this.setState({
                                                    confirmPassword:
                                                        e.target.value,
                                                })
                                            }></input>

                                        {this.state.confirmPasswordError
                                            .length > 0 && (
                                            <div className="error">
                                                {
                                                    this.state
                                                        .confirmPasswordError
                                                }
                                            </div>
                                        )}

                                        {this.state.passwordMismatch && (
                                            <div className="error">
                                                Passwords do not match
                                            </div>
                                        )}
                                    </form>

                                    <label className="label magin-top-20">
                                        Complete the below captcha
                                    </label>
                                    <div className="reCaptcha-container">
                                        <div className="center margin-top-12">
                                            <LoadCanvasTemplate />
                                        </div>
                                        <div className="center">
                                            <input
                                                type="text"
                                                className="reCaptcha-input"
                                                value={this.state.captchaValue}
                                                onChange={(e) =>
                                                    this.setState({
                                                        captchaValue:
                                                            e.target.value,
                                                    })
                                                }></input>
                                            <button
                                                className="reCaptcha-verify-button"
                                                disabled={this.state.verified}
                                                onClick={this.verifyCaptcha}>
                                                {this.state.verified ? (
                                                    <span
                                                        style={{
                                                            color: "green",
                                                            fontSize: 20,
                                                        }}>
                                                        &#10004;
                                                    </span>
                                                ) : (
                                                    "Verify"
                                                )}
                                            </button>
                                        </div>
                                    </div>

                                    <div className="center">
                                        <button
                                            className={
                                                !this.state.verified ||
                                                this.state.creatingAccount
                                                    ? "disabled-create-account-button"
                                                    : "create-account-button"
                                            }
                                            disabled={
                                                !this.state.verified ||
                                                this.state.creatingAccount
                                            }
                                            onClick={this.createAccount}>
                                            {this.state.creatingAccount
                                                ? "Please wait"
                                                : "Create Account"}
                                        </button>
                                    </div>
                                </div>
                            )}
                            {this.state.accountCreated && (
                                <div className="center">
                                    <button
                                        className="check-email-verification"
                                        disabled={true}>
                                        <div className="bold-text">
                                            Account created{" "}
                                            <span style={{ color: "green" }}>
                                                &#10004;
                                            </span>
                                        </div>
                                        <div>
                                            Please check your email for
                                            verification
                                        </div>
                                        <div className="small-text">
                                            It may be in your spam folder
                                        </div>
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
