import React, { Component } from "react";
import "./KickOffTime.css";

export default class KickOffTime extends Component {
    render() {
        return (
            <div className="center disable-text-select kickoff-box">
                <div className="kick-off">
                    KickOff: {this.props.startTime || "---"}
                </div>
                <div className="kick-off">
                    FullTime: {this.props.finishTime || "---"}
                </div>
            </div>
        );
    }
}
