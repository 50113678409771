import React, { Component } from "react";
import { Modal } from "react-responsive-modal";
import HttpService from "../../services/HttpService";
import "./VerifyEmail.css";

export default class VerifyEmail extends Component {
    constructor(props) {
        super(props);
        this.verifyEmail = this.verifyEmail.bind(this);
        this.redirectToHomePage = this.redirectToHomePage.bind(this);
        this.state = {
            openPopup: true,
            verified: false,
            redirect: false,
            tokenError: false,
        };
    }

    componentDidMount() {
        this.verifyEmail();
    }

    redirectToHomePage() {
        this.closeModal();
        this.props.history.push({
            pathname: "/",
        });
    }

    verifyEmail() {
        let url = this.props.location.pathname;
        let verificationToken = url.split("/").pop();

        let token = {
            Token: verificationToken,
        };

        if (verificationToken.length > 0) {
            HttpService.verifyEmail(token)
                .then((res) => {
                    if (res.status === 200) {
                        this.setState({ verified: true });

                        setTimeout(() => this.redirectToHomePage(), 3000);
                    }
                })
                .catch(() => {
                    this.setState({ tokenError: true });
                });
        } else {
            this.setState({ tokenError: true });
        }
    }

    closeModal() {
        this.setState({ openPopup: false });
    }

    render() {
        return (
            <div>
                <Modal
                    open={this.state.openPopup}
                    onClose={() => this.closeModal()}
                    center
                    classNames={{
                        modal: "verificationModal",
                    }}>
                    <div className="disable-text-select center-logo">
                        <img
                            className="logo"
                            src={require("../../assets/images/5v5ideLogoBlack.png")}
                            alt=""
                        />
                    </div>
                    {!this.state.tokenError && (
                        <div className="center margin-top-12">
                            <div>
                                {this.state.verified ? (
                                    <div className="center-align margin-top-12">
                                        <div>Thanks, you can now sign in</div>
                                        <div>Re-directing to home page</div>
                                    </div>
                                ) : (
                                    <div className="center-align margin-top-12">
                                        Verifying email
                                    </div>
                                )}
                                <button
                                    className="verifying-button"
                                    disabled={true}>
                                    {this.state.verified ? (
                                        <div>
                                            <div>
                                                Verified{" "}
                                                <span
                                                    style={{ color: "green" }}>
                                                    &#10004;
                                                </span>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>Please wait</div>
                                    )}
                                </button>
                            </div>
                        </div>
                    )}
                    {this.state.tokenError && (
                        <div className="center margin-top-20">
                            <div>
                                <div className="center margin-top-20 error-text">
                                    Verification token error
                                </div>
                                <button
                                    className="verifying-button"
                                    onClick={this.redirectToHomePage}>
                                    <div>Go to home page</div>
                                </button>
                            </div>
                        </div>
                    )}
                </Modal>
            </div>
        );
    }
}
