const DateOption = {
    AllTime: "All Time",
    ThisYear: "This Year",
    LastYear: "Last Year",
    ThisMonth: "This Month",
    LastMonth: "Last Month",
    Custom: "Custom",
};

export default DateOption;
