import React, { Component } from "react";
import { Modal } from "react-responsive-modal";
import HttpService from "../../services/HttpService";
import "./LinkPlayer.css";

export default class LinkPlayer extends Component {
    constructor(props) {
        super(props);
        this.linkPlayer = this.linkPlayer.bind(this);
        this.redirectToHomePage = this.redirectToHomePage.bind(this);
        this.state = {
            openPopup: true,
            linkToken: "",
            linked: false,
            rejected: false,
            redirect: false,
            requestError: false,
            error: "",
            tokenError: "",
            linkError: "",
            userError: "",
            playerError: "",
        };
    }

    componentDidMount() {
        let url = this.props.location.pathname;
        let linkToken = url.split("/").pop();

        this.setState({ linkToken: linkToken });
    }

    redirectToHomePage() {
        this.closeModal();
        this.props.history.push({
            pathname: "/",
        });
    }

    linkPlayer() {
        if (this.state.linkToken.length > 0) {
            let token = {
                LinkToken: this.state.linkToken,
            };

            HttpService.linkPlayer(token)
                .then((res) => {
                    if (res.status === 200) {
                        this.setState({ linked: true });

                        setTimeout(() => this.redirectToHomePage(), 3000);
                    }
                })
                .catch((err) => {
                    this.validationResponse(err.response.data);
                    this.setState({ requestError: true });
                });
        } else {
            this.setState({ requestError: true });
        }
    }

    rejectLink() {
        if (this.state.linkToken.length > 0) {
            let token = {
                LinkToken: this.state.linkToken,
            };

            HttpService.rejectPlayerLink(token)
                .then((res) => {
                    if (res.status === 200) {
                        this.setState({ rejected: true });

                        setTimeout(() => this.redirectToHomePage(), 3000);
                    }
                })
                .catch((err) => {
                    this.validationResponse(err.response.data);
                    this.setState({ requestError: true });
                });
        } else {
            this.setState({ requestError: true });
        }
    }

    validationResponse(response) {
        if (response.message) {
            this.setState({ error: response.message });
        }
        if (response.length > 0) {
            response.forEach((resp) => {
                if (resp.property === "LinkToken") {
                    this.setState({ tokenError: resp.message });
                } else if (resp.property === "Linked") {
                    this.setState({ linkError: resp.message });
                } else if (resp.property === "UserId") {
                    this.setState({ userError: resp.message });
                } else if (resp.property === "PlayerId") {
                    this.setState({ playerError: resp.message });
                }
            });
        }
    }

    closeModal() {
        this.setState({ openPopup: false });
    }

    render() {
        return (
            <div>
                <Modal
                    open={this.state.openPopup}
                    onClose={() => this.closeModal()}
                    center
                    classNames={{
                        modal: "linkPlayerModal",
                    }}>
                    <div className="disable-text-select center-logo">
                        <img
                            className="logo"
                            src={require("../../assets/images/5v5ideLogoBlack.png")}
                            alt=""
                        />
                    </div>

                    {!this.state.requestError && this.state.linked && (
                        <div>
                            <div className="center margin-top-50">
                                Player linked to Account
                            </div>
                            <button
                                className="center link-button"
                                onClick={this.redirectToHomePage}>
                                <div>Go to home page</div>
                            </button>
                        </div>
                    )}

                    {!this.state.requestError && this.state.rejected && (
                        <div>
                            <div className="center margin-top-50">
                                Player link rejected
                            </div>
                            <button
                                className="center link-button"
                                onClick={this.redirectToHomePage}>
                                <div>Go to home page</div>
                            </button>
                        </div>
                    )}

                    {!this.state.requestError &&
                        !this.state.rejected &&
                        !this.state.linked && (
                            <div className="center margin-top-12">
                                <div>
                                    <button
                                        className="center link-button"
                                        onClick={this.linkPlayer}>
                                        Link Player
                                    </button>
                                    <button
                                        className="center reject-button"
                                        onClick={this.rejectLink}>
                                        Reject Player link
                                    </button>
                                </div>
                            </div>
                        )}
                    {this.state.requestError && (
                        <div className="center margin-top-20">
                            <div>
                                <div className="margin-top-12 error-text">
                                    {this.state.error.length > 0 && (
                                        <div className="center">
                                            {this.state.error}
                                        </div>
                                    )}
                                    {this.state.linkError.length > 0 && (
                                        <div className="center">
                                            {this.state.linkError}
                                        </div>
                                    )}
                                    {this.state.tokenError.length > 0 && (
                                        <div className="center">
                                            {this.state.tokenError}
                                        </div>
                                    )}
                                    {this.state.userError.length > 0 && (
                                        <div className="center">
                                            {this.state.userError}
                                        </div>
                                    )}
                                    {this.state.playerError.length > 0 && (
                                        <div className="center">
                                            {this.state.playerError}
                                        </div>
                                    )}
                                </div>
                                <button
                                    className="center link-button"
                                    onClick={this.redirectToHomePage}>
                                    <div>Go to home page</div>
                                </button>
                            </div>
                        </div>
                    )}
                </Modal>
            </div>
        );
    }
}
