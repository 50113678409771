import React, { Component } from "react";
import "./UserOption.css";

export default class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
        };
    }

    expand() {
        this.state.expanded
            ? this.setState({ expanded: false })
            : this.setState({ expanded: true });
    }

    render() {
        return (
            <div>
                <div className="disable-text-select option separator">
                    <span
                        className="margin-top-12"
                        onClick={() => this.expand()}>
                        <span
                            className={
                                this.state.expanded
                                    ? "chevron-expanded"
                                    : "chevron-collapsed"
                            }>
                            ▼
                        </span>
                        Settings
                    </span>
                    <br />
                    <div className={this.state.expanded ? "" : "hidden"}>
                        <div className="center coming-soon">Coming Soon</div>
                    </div>
                </div>
            </div>
        );
    }
}
