import React, { Component } from "react";
import AvatarEditor from "react-avatar-editor";
import ReactDom from "react-dom";
import HttpService from "../../services/HttpService";
import AuthenticationService from "../../services/AuthenticationService";
import Select from "react-select";
import DatePicker from "react-date-picker";
import "./UserOption.css";
import "./flags.css";
import "./DatePicker.css";
import "react-responsive-modal/styles.css";

export default class EditPlayer extends Component {
    constructor(props) {
        super(props);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.cancelAvatarEdit = this.cancelAvatarEdit.bind(this);
        this.updateAvatarImage = this.updateAvatarImage.bind(this);
        this.setEditorRef = this.setEditorRef.bind(this);
        this.savePlayerChanges = this.savePlayerChanges.bind(this);
        this.state = {
            expanded: false,
            playerDetails: {},
            playerGuid: "",
            avatarUrl: "",
            newAvatarGuid: "",
            nickName: "",
            firstName: "",
            lastName: "",
            nationality: 0,
            nationalityId: 0,
            dob: new Date(),
            uploadingPlayer: false,
            editingAvatar: false,
            uploadingAvatar: false,
            avatarEditorImageScale: 1.0,
            avatarEditorUrl: "",
            nationalityOptions: [],
            nickNameError: "",
            firstNameError: "",
            lastNameError: "",
            avatarUploadError: false,
        };
    }

    componentDidMount() {
        this.setState({ dob: new Date() });
    }

    setEditorRef = (editor) => (this.editor = editor);

    expand() {
        if (this.state.expanded) {
            this.setState({ expanded: false });
        } else {
            this.setState({ expanded: true });
            if (this.props.isSignedIn) {
                this.fetchData();
            }
        }
    }

    fetchData() {
        if (this.props.isSignedIn) {
            HttpService.getEditPlayer().then((res) => {
                const playerDetails = res.data;
                this.setState({ avatarUrl: playerDetails.avatarUrl });
                this.setState({ newAvatarGuid: playerDetails.newAvatarGuid });
                this.setState({ playerGuid: playerDetails.playerGuid });
                this.setState({ nickName: playerDetails.nickName });
                this.setState({ firstName: playerDetails.firstName });
                this.setState({ lastName: playerDetails.lastName });
                this.setState({ nationalityId: playerDetails.nationalityId });
                if (
                    playerDetails.dob !== undefined &&
                    playerDetails.dob !== null
                ) {
                    this.setState({ dob: new Date(playerDetails.dob) });
                }
                this.fetchNationalities();
            });
        }
    }

    fetchNationalities() {
        HttpService.getNationalities().then((res) => {
            const nationalities = res.data;

            const options = nationalities.map((nat) => ({
                value: nat.id,
                label: nat.nationality,
                code: nat.code,
            }));

            this.setState({ nationalityOptions: options });

            if (
                this.state.nationalityId !== 0 &&
                this.state.nationalityId !== null
            ) {
                let nationality = options.find(
                    (nat) => nat.value === this.state.nationalityId
                );
                this.setState({ nationality: nationality });
            }
        });
    }

    handleFileChange(e) {
        this.setState({ editingAvatar: true });

        window.URL = window.URL || window.webkitURL;
        let url = window.URL.createObjectURL(e.target.files[0]);
        ReactDom.findDOMNode(this.refs.in).value = "";
        this.setState({ avatarEditorUrl: url });
    }

    avatarScale = (e) => {
        const scale = parseFloat(e.target.value);
        this.setState({ avatarEditorImageScale: scale });
    };

    cancelAvatarEdit() {
        this.setState({ editingAvatar: false });
        this.setState({ avatarEditorUrl: "" });
    }

    async updateAvatarImage() {
        this.setState({ avatarUploadError: false });
        let signedIn = await AuthenticationService.isSignedIn();
        if (signedIn) {
            this.setState({ uploadingAvatar: true }, () => {
                this.editor.getImageScaledToCanvas().toBlob((blob) => {
                    this.cancelAvatarEdit();

                    const fileName = this.state.newAvatarGuid + ".jpeg";
                    const file = new File([blob], fileName, {
                        type: "image/jpeg",
                    });

                    var formData = new FormData();

                    formData.append("file", file);

                    HttpService.updateAvatar(formData)
                        .then(async (res) => {
                            if (res.status === 200) {
                                await AuthenticationService.refreshToken();
                                window.location.reload(true);
                            } else {
                                console.log("Avatar upload failed");
                                this.setState({ uploadingAvatar: false });
                                this.setState({ avatarUploadError: true });
                            }
                        })
                        .catch((err) => {
                            console.log("Avatar upload failed");
                            this.setState({ uploadingAvatar: false });
                            this.setState({ avatarUploadError: true });
                        });
                });
            });
        }
    }

    async savePlayerChanges() {
        this.resetValidation();

        let signedIn = await AuthenticationService.isSignedIn();
        if (signedIn) {
            this.setState({ uploadingPlayer: true }, () => {
                const player = {
                    FirstName: this.state.firstName,
                    LastName: this.state.lastName,
                    NickName: this.state.nickName,
                    DOB: isNaN(Date.parse(this.state.dob))
                        ? null
                        : new Date(
                              this.state.dob.getTime() -
                                  this.state.dob.getTimezoneOffset() * 60000
                          ).toJSON(),
                    NationalityId:
                        this.state.nationality === 0
                            ? null
                            : this.state.nationality.value,
                };

                HttpService.updatePlayer(player)
                    .then((res) => {
                        if (res.status === 200) {
                            window.location.reload(true);
                        } else {
                            console.log("Player update failed");
                            this.setState({ uploadingPlayer: false });
                        }
                    })
                    .catch((err) => {
                        console.log("Player update failed");
                        this.validationResponse(err.response.data);
                        this.setState({ uploadingPlayer: false });
                    });
            });
        }
    }

    resetValidation() {
        this.setState({ nickNameError: "" });
        this.setState({ firstNameError: "" });
        this.setState({ lastNameError: "" });
    }

    validationResponse(response) {
        response.length > 0 &&
            response.forEach((resp) => {
                if (resp.property === "NickName") {
                    this.setState({ nickNameError: resp.message });
                } else if (resp.property === "FirstName") {
                    this.setState({ firstNameError: resp.message });
                } else if (resp.property === "LastName") {
                    this.setState({ lastNameError: resp.message });
                }
            });
    }

    render() {
        return (
            <div className="disable-text-select option separator">
                <span className="margin-top-12" onClick={() => this.expand()}>
                    <span
                        className={
                            this.state.expanded
                                ? "chevron-expanded"
                                : "chevron-collapsed"
                        }>
                        ▼
                    </span>
                    Edit Player
                </span>
                <br />
                <div className={this.state.expanded ? "" : "hidden"}>
                    <div className="center">
                        <div className="user-box">
                            {!this.state.editingAvatar ? (
                                <div className="center">
                                    <div className="user-player-avatar-container">
                                        <div className="user-player-avatar">
                                            <img
                                                className="user-player-avatar-image"
                                                src={
                                                    this.state.avatarUrl === ""
                                                        ? require("../../assets/images/userIcon.png")
                                                        : this.state.avatarUrl
                                                }
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                    <div className="change-avatar-container">
                                        <button
                                            className={
                                                this.state.uploadingAvatar
                                                    ? "center disabled-change-avatar-button"
                                                    : "center change-avatar-button"
                                            }>
                                            <label htmlFor="file-upload">
                                                {this.state.uploadingAvatar
                                                    ? "Refreshing"
                                                    : "Change Avatar"}
                                            </label>
                                            <input
                                                ref="in"
                                                id="file-upload"
                                                type="file"
                                                accept="image/*"
                                                onChange={this.handleFileChange}
                                                disabled={
                                                    this.state.uploadingAvatar
                                                }
                                            />
                                        </button>
                                        {this.state.avatarUploadError && (
                                            <div className="center error-no-margin">
                                                Error uploading avatar
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div className="avatar-editor">
                                    <div className="center">
                                        <AvatarEditor
                                            ref={this.setEditorRef}
                                            image={this.state.avatarEditorUrl}
                                            width={200}
                                            height={200}
                                            border={25}
                                            color={[255, 255, 255, 0.8]}
                                            scale={parseFloat(
                                                this.state
                                                    .avatarEditorImageScale
                                            )}
                                            rotate={0}
                                            className={"margin-top-12"}
                                        />
                                    </div>
                                    <br />
                                    <div className="center margin-top-minus-10">
                                        <label className="label">Zoom</label>
                                        <input
                                            name="scale"
                                            type="range"
                                            onChange={this.avatarScale}
                                            min="1"
                                            max="3"
                                            step="0.01"
                                            defaultValue="1"
                                        />
                                    </div>
                                    <div className="center margin-bottom-20">
                                        <button
                                            className="cancel-avatar-edit-button"
                                            onClick={this.cancelAvatarEdit}>
                                            Cancel
                                        </button>
                                        <button
                                            className="save-edit-button"
                                            onClick={this.updateAvatarImage}>
                                            Save
                                        </button>
                                    </div>
                                </div>
                            )}

                            <label className="label">Nickname</label>
                            <input
                                type="text"
                                className="textbox"
                                value={this.state.nickName}
                                onChange={(e) =>
                                    this.setState({ nickName: e.target.value })
                                }></input>
                            {this.state.nickNameError.length > 0 && (
                                <div className="error">
                                    {this.state.nickNameError}
                                </div>
                            )}

                            <label className="label">First name</label>
                            <input
                                type="text"
                                className="textbox"
                                value={this.state.firstName}
                                onChange={(e) =>
                                    this.setState({ firstName: e.target.value })
                                }></input>
                            {this.state.firstNameError.length > 0 && (
                                <div className="error">
                                    {this.state.firstNameError}
                                </div>
                            )}

                            <label className="label">Last name</label>
                            <input
                                type="text"
                                className="textbox"
                                value={this.state.lastName}
                                onChange={(e) =>
                                    this.setState({ lastName: e.target.value })
                                }></input>
                            {this.state.lastNameError.length > 0 && (
                                <div className="error">
                                    {this.state.lastNameError}
                                </div>
                            )}

                            <label className="label">Nationality</label>
                            <div className="select-box">
                                <Select
                                    placeholder="Select a nationality"
                                    options={this.state.nationalityOptions}
                                    onChange={(value) =>
                                        this.setState({
                                            nationality: value,
                                        })
                                    }
                                    className="select-option"
                                    value={this.state.nationality}
                                    getOptionLabel={(nat) => (
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}>
                                            <div className="f32">
                                                <div
                                                    className={
                                                        "flag " + nat.code
                                                    }></div>
                                            </div>
                                            <span style={{ marginLeft: 5 }}>
                                                {nat.label}
                                            </span>
                                        </div>
                                    )}
                                />
                            </div>

                            <label className="label">DOB</label>
                            <div className="dob-picker-box">
                                <DatePicker
                                    format="dd-MM-yyyy"
                                    openCalendarOnFocus={false}
                                    onChange={(value) =>
                                        this.setState({ dob: value })
                                    }
                                    value={this.state.dob}
                                    clearIcon={null}
                                    className="dob-picker"
                                />
                            </div>

                            <div className="center">
                                <button
                                    className={
                                        this.state.uploadingPlayer
                                            ? "disabled-save-edit-button"
                                            : "save-edit-button"
                                    }
                                    onClick={this.savePlayerChanges}
                                    disabled={this.state.uploadingPlayer}>
                                    {this.state.uploadingPlayer
                                        ? "Refreshing"
                                        : "Save"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
